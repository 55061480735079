import { SET_CATEGORIA_ID, SET_CATEGORIA_IMG, SET_CATEGORIA_NOMBRE, SET_ESTATUS_TAREA, SET_ID_EVALUACION, SET_ID_SEGMENTACION, SET_NOMBRE_TAREA_SELECIONADA, SET_TAREA_SELECCIONADA } from './OB_actions'

export interface Ob_State {
    IdCategoriaSeleccionada: string;
    ImgCategoriaSeleccionada: string;
    NombreCategoriaSeleccionada: string;
    IdTareaSeleccionada: string;
    NombreTareaSeleccionada: string;
    IdEvaluacion: string;
    EstatusTarea: string;
    IdSegmentacion: string;
}

const initialState: Ob_State = {
    IdCategoriaSeleccionada: "",
    ImgCategoriaSeleccionada:"",
    NombreCategoriaSeleccionada:"",
    IdTareaSeleccionada:"",
    NombreTareaSeleccionada:"",
    IdEvaluacion:"",
    EstatusTarea:"",
    IdSegmentacion:""
}

const Ob_reducer = (state = initialState, action: any ) =>{
    switch(action.type){
        case SET_CATEGORIA_ID:
            return {...state, IdCategoriaSeleccionada: action.payload}
        case SET_CATEGORIA_IMG:
            return {...state, ImgCategoriaSeleccionada: action.payload}
        case SET_CATEGORIA_NOMBRE:
            return {...state, NombreCategoriaSeleccionada: action.payload}
        case SET_TAREA_SELECCIONADA:
            return {...state, IdTareaSeleccionada: action.payload}
        case SET_NOMBRE_TAREA_SELECIONADA:
            return {...state, NombreTareaSeleccionada: action.payload}
        case SET_ID_EVALUACION:
            return {...state, IdEvaluacion: action.payload}
        case SET_ESTATUS_TAREA:
            return {...state, EstatusTarea: action.payload}
        case SET_ID_SEGMENTACION:
            return {...state, IdSegmentacion: action.payload}
        default:
            return state
    }
}

export default Ob_reducer
