import Ob_reducer, { Ob_State } from '../OverBoard/OB_reducer'
import LB_reducer, { LB_State, } from '../LibretaTareas/LB_reducer'
import NF_reducer, { NF_State, } from '../NewForms/NF_reducer'
import { combineReducers } from "redux";

export interface RootState {
    OverBoard: Ob_State,
    LibretaTareas: LB_State,
    NewForm: NF_State
}

const rootReducers = combineReducers({
    OverBoard: Ob_reducer,
    LibretaTareas: LB_reducer,
    NewForm: NF_reducer
})

export default rootReducers
