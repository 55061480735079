import React, { useRef, useState } from "react";
import { Button, TextBox } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import PreguntasBox from '../../components/Cuestionario_Pregunta/Cuestionario_Pregunta'
import { connect } from 'react-redux'
import { RootState } from '../../reduxStore/reducers'
import * as Fnctn from '../../contexts/GenericFunctions';
import '../Cuestionario/Cuestionario.scss'
import { Link } from 'react-router-dom'
import {halcoloaderActive, halcoloaderRemove} from '../../contexts/HalcoLoader' 

function Cuestionario(props: any) {
    const tbTitulo = useRef<any>(null);
    const tbPregunta = useRef<any>(null);
    const [visibleTbTitulo, setvisbleTbTitulo] = useState<boolean>(false);
    const [visibleTbPregunta, setvisibleTbPregunta] = useState<boolean>(false);
    const [visibleBtnPregunta, setVisibleBtnPregunta] = useState<boolean>(true);
    const [visibleOptionsTitulo, setVisibleOptionsTitulo] = useState<boolean>(true);
    const [valuesQuestions, setValuesQuestions] = useState<any>(props.Select_questions);
    const [Titulo, setTitulo] = useState<string>(props.Select_name);
    const [NuevaPregunta, setNuevaPregunta] = useState<string>("");
    const [key, setKey] = useState<number>(0);

    const btnEdit = async () => {
        await setvisbleTbTitulo(true);
        await setVisibleOptionsTitulo(false);
        tbTitulo.current.instance.focus();
        tbTitulo.current.instance.option('value', Titulo);
    }

    const OutTbTitulo = async () => {
        await setvisbleTbTitulo(false);
        await setVisibleOptionsTitulo(true);
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();

        let dtEvaluation = await fetch(`${api}/training/evaluations/${props.Select_IdForm}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
            // body: JSON.stringify(objtUpdate)
        });

        let evaluation = await dtEvaluation.json();

        evaluation.data[0].name = Titulo;

        let dtTask = await fetch(`${api}/training/evaluations/${props.Select_IdForm}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            },
            body: JSON.stringify(evaluation.data[0])
        });

        await dtTask.json();
    }

    const ChangeText = (e: any, txb: string) => {
        switch (txb) {
            case "Titulo":
                setTitulo(e.component._changedValue)
                break
            case "Pregunta":
                setNuevaPregunta(e.component._changedValue)
                break
        }
    }

    const getEvaluations = async () => {
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();
        let dtEvaluation = await fetch(`${api}/training/evaluations/${props.Select_IdForm}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });
        return await dtEvaluation.json()
    }

    const AddNuevaPregunta = async () => {
        if (!Fnctn.NullUndefinedEmpty(NuevaPregunta)) {
            // setloading(true);
            halcoloaderActive();
            const data = Fnctn.getCookie();
            const api = Fnctn.GetAPI();
            let Objt = {
                "question": NuevaPregunta,
                "response_type": "text",
                "mandatory": false,
                "answers": [],
                "evaluation": false,
                "correct_answers": [],
                "points": 0,
                "explanation": "",
                "_id": ""
            }

            let dtEvaluation = await fetch(`${api}/training/evaluations/${props.Select_IdForm}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                }
            });

            let evaluation = await dtEvaluation.json()

            evaluation.data[0].questionnaire[0].questions.push(Objt)


            let dtTask = await fetch(`${api}/training/evaluations/${props.Select_IdForm}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                },
                body: JSON.stringify(evaluation.data[0])
            });

            await dtTask.json()

            evaluation = await getEvaluations()

            await setValuesQuestions(evaluation.data[0].questionnaire[0].questions)
            await setvisibleTbPregunta(false)
            await setVisibleBtnPregunta(true)
            await setKey(key + 1);
            // await setloading(false)
            halcoloaderRemove();
            notify("Pregunta agregada", "success")
        }
        else {
            notify("No puedes agregar una pregunta en blanco", "error")
        }
    }

    const handleClick = (Button: String) => {
        switch (Button) {
            case "btnNuevaPregunta":
                setvisibleTbPregunta(true)
                setVisibleBtnPregunta(false)
                break;
            case "btnAgregar":
                AddNuevaPregunta();
                break;
            case "btnCancelar":
                setvisibleTbPregunta(false)
                setVisibleBtnPregunta(true)
                break;
        }
    };

    return (
        <React.Fragment>
          
            <div className={'content-block dx-card responsive-paddings'} style={{margin: "30px 0px 30px 190px", width: "80%"}}>
                <div className='btnAtras' style={{ margin: "0px 0px 35px 0px" }}>
                    <Link to={'/LibretaTareas'}>
                        <Button className='' icon="back" text="Atras"></Button>
                    </Link>
                </div>
                <div style={{ margin: '0px 0px 25px 0px' }}><label style={{ fontSize: '25px' }}>Cuestionario</label></div>
                <div className='card'>
                    <div className="card-content">
                        <div className="grid-container">
                            {visibleOptionsTitulo && <label className="tituloCuestionario">{Titulo}</label>}
                            {visibleTbTitulo &&
                                <TextBox
                                    ref={tbTitulo}
                                    label='Titulo'
                                    placeholder='Debes ingresar un título'
                                    className="tbTitulo"
                                    onFocusOut={OutTbTitulo}
                                    onChange={(e) => ChangeText(e, "Titulo")}
                                ></TextBox>
                            }
                            {visibleOptionsTitulo && <i className="dx-icon-edit" onClick={btnEdit}></i>}
                            <div className="BoxPreguntas"><PreguntasBox key={key} onClickAdd={valuesQuestions} id_Form={props.Select_IdForm} /></div>
                            {visibleTbPregunta &&
                                <div>
                                    <TextBox
                                        ref={tbPregunta}
                                        label='Pregunta'
                                        placeholder='Debes ingresar una pregunta'
                                        className="tbPregunta"
                                        onChange={(e) => ChangeText(e, "Pregunta")}
                                    ></TextBox>
                                    <Button
                                        className="btnCancelar"
                                        text="Cancelar"
                                        onClick={() => handleClick("btnCancelar")}
                                    ></Button>
                                    <Button
                                        className="btnAgregar"
                                        text="Agregar"
                                        onClick={() => handleClick("btnAgregar")}
                                    ></Button>
                                </div>
                            }
                            {visibleBtnPregunta &&
                                <Button
                                    className="btnNuevaTarea"
                                    text="Nueva pregunta"
                                    onClick={() => handleClick("btnNuevaPregunta")}
                                ></Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

// export default NewForm;
const mapStateToProps = (state: RootState) => {
    return {
        Select_IdForm: state.NewForm.id_Form,
        Select_name: state.NewForm.name,
        Select_questions: state.NewForm.questions
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cuestionario)