import {  useState  } from 'react';
import * as Fnctn from '../../../contexts/GenericFunctions';
import { TextBox, Button } from 'devextreme-react';
import * as nfActions from '../../../reduxStore/NewForms/NF_actions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../NewForm/NewForm.scss'
import {halcoloaderActive, halcoloaderRemove} from '../../../contexts/HalcoLoader'

interface IProps {
    props: any
}

function NewForm({ props }: IProps){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const api = Fnctn.GetAPI();
    const data = Fnctn.getCookie();
    const [titulo, setTitulo] = useState<string>()
    // constructor(props: IProps) {
    //     super(props);
    //     this.state = {
    //         loading: false,
    //         titulo: ""
    //     }
    // }

    const ViewForm = async (navigate:any, dispatch: any, objTask: any) => {
        // setloading(true);
        halcoloaderActive();
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();
        let dtTask = await fetch(`${api}/training/evaluations/${objTask.evaluation_id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });
    
        let dtForm = await dtTask.json()
        let questions:any = Object.values(dtForm.data[0].questionnaire[0].questions)
        dispatch(nfActions.set_form_id(dtForm.data[0]._id))
        dispatch(nfActions.set_form_name(dtForm.data[0].name))
        dispatch(nfActions.set_form_questions(questions))
        await navigate('/Cuestionario')
        // setloading(true);
        halcoloaderRemove();
    }

    const Guardar = async () => {
        // setloading(true);
        halcoloaderActive();
        let Objt = await {
            "name": titulo,
            "questionnaire": [
                {
                    "_id": "",
                    "title": "Sin titulo",
                    "description": "",
                    "questions": [
                        {
                            "_id": "",
                            "question": "Primera pregunta",
                            "response_type": "text",
                            "mandatory": false,
                            "answers": [],
                            "evaluation": false,
                            "correct_answers": [],
                            "points": 0,
                            "explanation": ""
                        }
                    ]
                }
            ]
        }

        const dtresult = await fetch(`${api}/training/evaluations`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            },
            body: JSON.stringify(Objt)
        });
        const dtTasks = await dtresult.json();
        // console.log(dtTasks)

        let Objt_task = {
            "evaluation_id": dtTasks.data.insertedId
        }

        let dtTask = await fetch(`${api}/training/task/${props._idTarea}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            },
            body: JSON.stringify(Objt_task)
        });

        dtTask = await fetch(`${api}/training/task/${props._idTarea}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });

        let objTask:any = await dtTask.json()

        ViewForm(navigate, dispatch, objTask.data[0])
        // setloading(false);
        halcoloaderRemove();
    }

    const handleTextChanged = (e: any) => {
        setTitulo(e)
    }

    return (
        <div className='windowPrincipal'>
            <div className='WinNewForm'>
                <p>Puedes agregar un cuestionario al final de la tarea para tomar un breve examen o recibir feedback.</p>
                <hr className='separador'></hr>
                <TextBox
                    label='Titulo'
                    placeholder='Debes ingresar un título'
                    onValueChange={(e) => handleTextChanged(e)}
                ></TextBox>
            </div>
            <div className='Botones'>
                {/* <Button className='btnOpcionesForm Cancelar' onClick={() => thisCancelar()}>Cancelar</Button> */}
                <Button className='btnOpcionesForm Guardar' onClick={() => Guardar()}>Guardar</Button>
            </div>
        </div>
    );

}

export default NewForm;
