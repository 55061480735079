import React, { Component } from 'react';
import { List, Switch } from 'devextreme-react';
import * as Fnctn from '../../contexts/GenericFunctions';
import { TextArea, TextBox, Button, Popup, RadioGroup, DropDownButton } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import '../../pages/Cuestionario/Cuestionario.scss'
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader'
import { OpcionesCorrectas, SaveOptions } from '../Cuestionario_Pregunta/Cuestionario_OpcionesCorrectas'

interface IProps {
    onClickAdd: any,
    id_Form: any
}
interface IState {
    Preguntas: any,
    visiblePopUp: boolean,
    Opcion: any,
    visibleNuevaOpcion: boolean,
    TipoRespuesta: string,
    opcionesOPCM: any,
    opcionesOPCU: any,
    RefOPCM: any,
    RefOPCU: any,
    visibleTBOpcion: boolean,
    visibleRespuestasCorrectas: boolean,
    obligatorio: boolean,
    OpcionCorrecta: any,
    RefOpcionesCorrectos: any,
    titleQuestion: string
    puntos: number
    explicacionRespuesta: string,
    allquestions: any,
    idPregunta: string,
    ComponentPregunta: any,
    correctAnswers: any
}

class PreguntasBox extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            Preguntas: [],
            visiblePopUp: false,
            Opcion:
                <div className="dx-field">
                    <div className="dx-field-label labels">
                        <label>Sin Tipo de respuesta</label>
                    </div>
                </div>,
            visibleNuevaOpcion: false,
            TipoRespuesta: "text",
            opcionesOPCM: [],
            opcionesOPCU: [],
            RefOPCM: React.createRef(),
            RefOPCU: React.createRef(),
            RefOpcionesCorrectos: React.createRef(),
            visibleTBOpcion: false,
            visibleRespuestasCorrectas: false,
            obligatorio: false,
            OpcionCorrecta: <div className="dx-field">
                <div className="dx-field-label labels">
                    <label>Sin Tipo de respuesta</label>
                </div>
            </div>,
            titleQuestion: "",
            puntos: 0,
            explicacionRespuesta: "",
            allquestions: this.props.onClickAdd,
            idPregunta: "",
            ComponentPregunta: <div></div>,
            correctAnswers: []
        }
    }

    componentDidMount() {
        this.setState({ ComponentPregunta: this.Preguntas(null) })
    }

    handlePopupHidden = async () => {
        halcoloaderActive();
        await this.setState({ visiblePopUp: false });
        await this.GetActualQuestions();
        halcoloaderRemove();
    };

    ItemsMenu = [
        { id: 1, name: 'Duplicar' },
        { id: 2, name: 'Eliminar' }
    ];

    getEvaluations = async () => {
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();
        let dtEvaluation = await fetch(`${api}/training/evaluations/${this.props.id_Form}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });
        return await dtEvaluation.json()
    }

    updateEvaluations = async (objt: any) => {
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();
        await fetch(`${api}/training/evaluations/${this.props.id_Form}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            },
            body: JSON.stringify(objt)
        });
    }

    getTexboxOptions = (option: any, _id: any) => {
        return (<div className='optionsList'>
            <TextBox
                visible={true}
                value={option}
                stylingMode='underlined'
                className='optionList'
                maxLength={"15rem"}
                onValueChange={(e) => this.valueChangeTextOption(e, _id)}
                style={{ Width: "50px" }}
            /><i className='dx-icon dx-icon-remove icon_optionList' onClick={() => this.deleteOptionQuestion(_id)}></i>
        </div>)
    }

    valueChangeTextOption = async (e: any, Id_Opcion: string) => {
        halcoloaderActive();
        if (!Fnctn.NullUndefinedEmpty(e)) {
            let obj: any = await this.getEvaluations();
            obj = obj.data[0];
            for (let i = 0; i < obj.questionnaire[0].questions.length; i++) {
                if (obj.questionnaire[0].questions[i]._id === this.state.idPregunta) {
                    for (let j = 0; j < obj.questionnaire[0].questions[i].answers.length; j++) {
                        if (obj.questionnaire[0].questions[i].answers[j]._id === Id_Opcion) {
                            obj.questionnaire[0].questions[i].answers[j].option = e
                            this.updateEvaluations(obj);
                            let nwObj: any = await this.getEvaluations();
                            nwObj = nwObj.data[0]
                            if (obj.questionnaire[0].questions[i].response_type === "opcm") {
                                await this.setState({ opcionesOPCM: nwObj.questionnaire[0].questions[i].answers });
                            }
                            else if (obj.questionnaire[0].questions[i].response_type === "opcu") {
                                await this.setState({ opcionesOPCU: nwObj.questionnaire[0].questions[i].answers });
                            }
                            break
                        }
                    }
                }
            }
            let opciones_Text: any = [];
            for (let i = 0; i < obj.questionnaire[0].questions.length; i++) {
                if (obj.questionnaire[0].questions[i]._id === this.state.idPregunta) {
                    for (let j = 0; j < obj.questionnaire[0].questions[i].answers.length; j++) {
                        let optionName = obj.questionnaire[0].questions[i].answers[j].option;
                        let _idOption = obj.questionnaire[0].questions[i].answers[j]._id;
                        let option: any = await this.getTexboxOptions(optionName, _idOption)
                        await opciones_Text.push(option)
                    }
                    this.valueSwitchChanged(obj.questionnaire[0].questions[i].evaluation)
                    await this.setState({ visibleRespuestasCorrectas: obj.questionnaire[0].questions[i].evaluation });
                }
                this.Preguntas()
            }
            await this.setState({ Opcion: opciones_Text });
        }
        else {
            notify("No puedes dejar la opción vacía", "error")
        }
        halcoloaderRemove();
    }

    insertOption = async (tipoRespuesta: string, opcion: any) => {
        halcoloaderActive();
        if (!Fnctn.NullUndefinedEmpty(opcion)) {
            let registros = [];
            if(tipoRespuesta === "opcm"){
                registros = this.state.opcionesOPCM
            }
            else if (tipoRespuesta === "opcu"){
                registros = this.state.opcionesOPCU
            }
            let dtEvaluation = await this.getEvaluations()
            let NuevaOpcion = {
                option: opcion,
                _id: ""
            }
            let type: string = ""
            registros.push(NuevaOpcion)
            switch (tipoRespuesta) {
                case "opcu":
                    type = "opcu"
                    if (this.state.opcionesOPCU.length <= 0) {
                        await this.setState({opcionesOPCU: NuevaOpcion});
                    }
                    else{
                        await this.setState(prevState => ({
                            opcionesOPCU: [...prevState.opcionesOPCU, NuevaOpcion]
                        }));
                    }
                    let opciones_Text_opcu: any = [];
                    for (let i = 0; i < this.state.opcionesOPCU.length; i++) {
                        let optionName = this.state.opcionesOPCU[i].option;
                        let _idOption = this.state.opcionesOPCU[i]._id;
                        let option: any = await this.getTexboxOptions(optionName, _idOption);
                        opciones_Text_opcu.push(option);
                    }
                    await this.setState({
                        Opcion: opciones_Text_opcu
                    })
                    break
                case "opcm":
                    type = "opcm"
                    if (this.state.opcionesOPCM.length <= 0) {
                        await this.setState({opcionesOPCM: NuevaOpcion});
                    }
                    else{
                        await this.setState(prevState => ({
                            opcionesOPCM: [...prevState.opcionesOPCM, NuevaOpcion]
                        }));
                    }
                    let opciones_Text_opcm: any = [];
                    for (let i = 0; i < this.state.opcionesOPCM.length; i++) {
                        let optionName = this.state.opcionesOPCM[i].option;
                        let _idOption = this.state.opcionesOPCM[i]._id;
                        let option: any = await this.getTexboxOptions(optionName, _idOption);
                        opciones_Text_opcm.push(option);
                    }
                    await this.setState({
                        Opcion: opciones_Text_opcm
                    })
                    break
            }
            for (let i = 0; i < dtEvaluation.data[0].questionnaire[0].questions.length; i++) {
                if (dtEvaluation.data[0].questionnaire[0].questions[i]._id === this.state.idPregunta) {
                    dtEvaluation.data[0].questionnaire[0].questions[i].answers = registros
                    if (type === "opcm") await this.setState({ opcionesOPCM: dtEvaluation.data[0].questionnaire[0].questions[i].answers });
                    else if (type === "opcu") await this.setState({ opcionesOPCU: dtEvaluation.data[0].questionnaire[0].questions[i].answers });
                    this.valueSwitchChanged(dtEvaluation.data[0].questionnaire[0].questions[i].evaluation)
                    await this.setState({ visibleRespuestasCorrectas: dtEvaluation.data[0].questionnaire[0].questions[i].evaluation });
                }
            }
            this.updateEvaluations(dtEvaluation.data[0]);
            for (let i = 0; i < dtEvaluation.data[0].questionnaire[0].questions.length; i++) {
                if (dtEvaluation.data[0].questionnaire[0].questions[i]._id === this.state.idPregunta) {
                    this.valueSwitchChanged(dtEvaluation.data[0].questionnaire[0].questions[i].evaluation)
                    await this.setState({ visibleRespuestasCorrectas: dtEvaluation.data[0].questionnaire[0].questions[i].evaluation });
                }
            }
        }
        halcoloaderRemove();
    }

    deleteOptionQuestion = async (_id: string) => {
        halcoloaderActive();
        let obj: any = await this.getEvaluations();
        obj = obj.data[0];
        for (let i = 0; i < obj.questionnaire[0].questions.length; i++) {
            if (obj.questionnaire[0].questions[i]._id === this.state.idPregunta) {
                let questions = obj.questionnaire[0].questions[i].answers.filter((element: any) => element._id !== _id)
                let correct_answers = obj.questionnaire[0].questions[i].correct_answers.filter((element: any) => element._id !== _id)
                obj.questionnaire[0].questions[i].answers = questions
                obj.questionnaire[0].questions[i].correct_answers = correct_answers
                this.updateEvaluations(obj);
                let nwObj: any = await this.getEvaluations();
                nwObj = nwObj.data[0]
                if (obj.questionnaire[0].questions[i].response_type === "opcm") {
                    await this.setState({ opcionesOPCM: nwObj.questionnaire[0].questions[i].answers });
                }
                else if (obj.questionnaire[0].questions[i].response_type === "opcu") {
                    await this.setState({ opcionesOPCU: nwObj.questionnaire[0].questions[i].answers });
                }
                break
            }
        }
        let opciones_Text: any = [];
        for (let i = 0; i < obj.questionnaire[0].questions.length; i++) {
            if (obj.questionnaire[0].questions[i]._id === this.state.idPregunta) {
                for (let j = 0; j < obj.questionnaire[0].questions[i].answers.length; j++) {
                    let optionName = obj.questionnaire[0].questions[i].answers[j].option;
                    let _idOption = obj.questionnaire[0].questions[i].answers[j]._id;
                    let option: any = await this.getTexboxOptions(optionName, _idOption);
                    opciones_Text.push(option);
                }
            }
        }
        await this.setState({ Opcion: opciones_Text });
        for (let i = 0; i < obj.questionnaire[0].questions.length; i++) {
            if (obj.questionnaire[0].questions[i]._id === this.state.idPregunta) {
                this.valueSwitchChanged(obj.questionnaire[0].questions[i].evaluation)
                await this.setState({ visibleRespuestasCorrectas: obj.questionnaire[0].questions[i].evaluation });
            }
        }
        this.getCorrectAnswers();
        halcoloaderRemove();
    }

    ContextualComponent = async (context: string, typeContext: string) => {
        let obj: any = await this.getEvaluations();
        obj = obj.data[0];
        switch (context) {
            case "text":
                await this.setState({ opcionesOPCU: [] })
                await this.setState({ opcionesOPCM: [] })
                await this.setState({
                    TipoRespuesta: "text",
                    visibleNuevaOpcion: false,
                    Opcion:
                        <div className="dx-field">
                            <div className="dx-field-label labels">
                                <label>( TIPO DE RESPUESTA TEXTO )</label>
                            </div>
                        </div>,
                    visibleRespuestasCorrectas: false
                });
                for (let i = 0; i < obj.questionnaire[0].questions.length; i++) {
                    if (obj.questionnaire[0].questions[i]._id === this.state.idPregunta) {
                        obj.questionnaire[0].questions[i].response_type = context
                        obj.questionnaire[0].questions[i].answers = [];
                        obj.questionnaire[0].questions[i].correct_answers = [];
                        obj.questionnaire[0].questions[i].points = 0;
                        obj.questionnaire[0].questions[i].evaluation = false;
                    }
                }
                break
            case "numeric":
                await this.setState({ opcionesOPCU: [] })
                await this.setState({ opcionesOPCM: [] })
                await this.setState({
                    TipoRespuesta: "numeric",
                    visibleNuevaOpcion: false,
                    Opcion:
                        <div className="dx-field">
                            <div className="dx-field-label labels">
                                <label>( TIPO DE RESPUESTA NÚMERO )</label>
                            </div>
                        </div>,
                    visibleRespuestasCorrectas: false
                });
                for (let i = 0; i < obj.questionnaire[0].questions.length; i++) {
                    if (obj.questionnaire[0].questions[i]._id === this.state.idPregunta) {
                        obj.questionnaire[0].questions[i].response_type = context
                        obj.questionnaire[0].questions[i].answers = [];
                        obj.questionnaire[0].questions[i].correct_answers = [];
                        obj.questionnaire[0].questions[i].points = 0;
                        obj.questionnaire[0].questions[i].evaluation = false;
                    }
                }
                break
            case "opcm":
                let opciones_Text_opcm: any = [];
                if (typeContext === "change") {
                    // this.setState({ opcionesOPCM: [{ _id: "0", option: "Primera respuesta" }] });
                    // let optionName = "Primera respuesta";
                    // let _idOption = "0";
                    // let option: any = await this.getTexboxOptions(optionName, _idOption);
                    // opciones_Text_opcm.push(option);
                    this.GetActualQuestions();
                    // this.insertOption("opcm", "Primera respuesta");
                }
                else {
                    for (let i = 0; i < this.state.opcionesOPCM.length; i++) {
                        let optionName = this.state.opcionesOPCM[i].option;
                        let _idOption = this.state.opcionesOPCM[i]._id;
                        let option: any = await this.getTexboxOptions(optionName, _idOption);
                        opciones_Text_opcm.push(option);
                    }
                }
                await this.setState({
                    TipoRespuesta: "opcm",
                    visibleNuevaOpcion: true
                });
                await this.setState({ Opcion: opciones_Text_opcm });
                for (let i = 0; i < obj.questionnaire[0].questions.length; i++) {
                    if (obj.questionnaire[0].questions[i]._id === this.state.idPregunta) {
                        obj.questionnaire[0].questions[i].response_type = context
                        if (typeContext === "edit") {
                            obj.questionnaire[0].questions[i].answers = this.state.opcionesOPCM;
                            obj.questionnaire[0].questions[i].correct_answers = obj.questionnaire[0].questions[i].correct_answers;
                            obj.questionnaire[0].questions[i].points = this.state.puntos;
                        }
                        else {
                            // obj.questionnaire[0].questions[i].answers = [{ _id: "", option: "Primera respuesta" }];
                            obj.questionnaire[0].questions[i].correct_answers = [];
                            obj.questionnaire[0].questions[i].points = 0;
                            // await this.setState({ opcionesOPCM: { _id: "", option: "Primera respuesta" } });
                        }
                    }
                }
                break
            case "opcu":
                let opciones_Text_opcu: any = [];
                if (typeContext === "change") {
                    // this.setState({ opcionesOPCU: [{ _id: "0", option: "Primera respuesta" }] });
                    // let optionName = "Primera respuesta";
                    // let _idOption = "0";
                    // let option: any = await this.getTexboxOptions(optionName, _idOption);
                    // opciones_Text_opcu.push(option);
                    // this.AgregarOpcion(this.state.TipoRespuesta)
                    // this.insertOption("opcu", "Primera respuesta");
                    this.GetActualQuestions();
                }
                else {
                    for (let i = 0; i < this.state.opcionesOPCU.length; i++) {
                        let optionName = this.state.opcionesOPCU[i].option;
                        let _idOption = this.state.opcionesOPCU[i]._id;
                        let option: any = await this.getTexboxOptions(optionName, _idOption);
                        opciones_Text_opcu.push(option);
                    }
                }
                await this.setState({
                    TipoRespuesta: "opcu",
                    visibleNuevaOpcion: true
                });
                await this.setState({ Opcion: opciones_Text_opcu });
                for (let i = 0; i < obj.questionnaire[0].questions.length; i++) {
                    if (obj.questionnaire[0].questions[i]._id === this.state.idPregunta) {
                        obj.questionnaire[0].questions[i].response_type = context
                        if (typeContext === "edit") {
                            obj.questionnaire[0].questions[i].answers = this.state.opcionesOPCU;
                            obj.questionnaire[0].questions[i].correct_answers = obj.questionnaire[0].questions[i].correct_answers;
                            obj.questionnaire[0].questions[i].points = this.state.puntos;
                        }
                        else {
                            // obj.questionnaire[0].questions[i].answers = [{ _id: "", option: "Primera respuesta" }];
                            obj.questionnaire[0].questions[i].correct_answers = [];
                            obj.questionnaire[0].questions[i].points = 0;
                        }
                    }
                }
                break
            case "file":
                await this.setState({ opcionesOPCU: [] })
                await this.setState({ opcionesOPCM: [] })
                await this.setState({
                    TipoRespuesta: "file",
                    visibleNuevaOpcion: false,
                    Opcion:
                        <div className="dx-field">
                            <div className="dx-field-label labels">
                                <label>( TIPO DE RESPUESTA ARCHIVO )</label>
                            </div>
                        </div>
                });
                for (let i = 0; i < obj.questionnaire[0].questions.length; i++) {
                    if (obj.questionnaire[0].questions[i]._id === this.state.idPregunta) {
                        obj.questionnaire[0].questions[i].response_type = context
                        obj.questionnaire[0].questions[i].answers = [];
                        obj.questionnaire[0].questions[i].correct_answers = [];
                        obj.questionnaire[0].questions[i].points = 0;
                    }
                }
                break
        }
        this.updateEvaluations(obj);
    }

    handleSelectionChanged = (e: any) => {
    }

    onItemClickList = (e:any) => {
        console.log(e)
        this.setState({correctAnswers: e});
    }

    getCorrectAnswers = async () => {
        halcoloaderActive();
        let dtQuestion: any = await this.getEvaluations();
        dtQuestion = dtQuestion.data[0];

        let Pregunta: any = dtQuestion.questionnaire[0].questions.filter((item: any) => item._id === this.state.idPregunta);
        Pregunta = Pregunta[0];

        let correct_answers = await Pregunta.correct_answers;
        let ids_CorrectAnswers: any = []

        correct_answers.forEach((element: any) => { ids_CorrectAnswers.push(element._id) });
        await this.setState({
            OpcionCorrecta: <OpcionesCorrectas answers={Pregunta.answers} ids_CorrectAnswers={ids_CorrectAnswers} onItemClickList={this.onItemClickList}></OpcionesCorrectas>
        });
        halcoloaderRemove();
    }

    AgregarOpcion = async (TipoRespuesta: string) => {
        halcoloaderActive();
        switch (TipoRespuesta) {
            case "opcm":
                let opciones_Text_opcm: any = [];
                for (let i = 0; i < this.state.opcionesOPCM.length; i++) {
                    let optionName = this.state.opcionesOPCM[i].option;
                    let _idOption = this.state.opcionesOPCM[i]._id;
                    let option: any = await this.getTexboxOptions(optionName, _idOption);
                    opciones_Text_opcm.push(option);
                }
                await this.setState({ visibleTBOpcion: true })
                await this.setState({
                    Opcion: <div>
                        <TextBox
                            visible={true}
                            ref={this.state.RefOPCM}
                            className='texto'
                            label='Respuesta'
                            maxLength={"15rem"}
                            onFocusOut={() => this.insertOption("opcm", this.state.RefOPCM.current.instance.option('value'))}
                        />
                        {opciones_Text_opcm}
                    </div>
                })
                break
            case "opcu":
                let opciones_Text_opcu: any = [];
                for (let i = 0; i < this.state.opcionesOPCU.length; i++) {
                    let optionName = this.state.opcionesOPCU[i].option;
                    let _idOption = this.state.opcionesOPCU[i]._id;
                    let option: any = await this.getTexboxOptions(optionName, _idOption);
                    opciones_Text_opcu.push(option);
                }
                await this.setState({ visibleTBOpcion: true })
                await this.setState({
                    Opcion: <div>
                        <TextBox
                            visible={true}
                            ref={this.state.RefOPCU}
                            className='texto'
                            label='Respuesta'
                            maxLength={"15rem"}
                            onFocusOut={() => this.insertOption("opcu", this.state.RefOPCU.current.instance.option('value'))}
                        />
                        {opciones_Text_opcu}
                    </div>
                })
                break
        }

        this.getCorrectAnswers();

        halcoloaderRemove();
    }

    valueSwitchChanged = async (e: any) => {
        halcoloaderActive();
        let registros = !Fnctn.NullUndefinedEmpty(this.state.opcionesOPCM) ? this.state.opcionesOPCM : this.state.opcionesOPCU

        if (e.value || e) {
            if (!Fnctn.NullUndefinedEmpty(registros)) {
                this.getCorrectAnswers();
            }
            else {
                await this.setState({ visibleRespuestasCorrectas: false });
                notify("Debes agregar al menos 1 opción", "error")
            }
        }
        else {
            await this.setState({ visibleRespuestasCorrectas: false });
        }
        halcoloaderRemove();
    }

    SaveOptions = async () => {
        try {
            halcoloaderActive();
            let dtQuestion: any = await this.getEvaluations();
            dtQuestion = dtQuestion.data[0];
            let arrayCorrectAnswers: any = [];

            let Pregunta: any = dtQuestion.questionnaire[0].questions.filter((item: any) => item._id === this.state.idPregunta);
            Pregunta = Pregunta[0];

            if (this.state.visibleRespuestasCorrectas) {
                if (this.state.correctAnswers.length > 0) {
                    arrayCorrectAnswers = await this.state.correctAnswers;
                }
                else {
                    let dtQuestion: any = await this.getEvaluations();
                    dtQuestion = dtQuestion.data[0];

                    let Pregunta: any = dtQuestion.questionnaire[0].questions.filter((item: any) => item._id === this.state.idPregunta);
                    Pregunta = Pregunta[0];
                    if (Pregunta.correct_answers.length > 0){
                        arrayCorrectAnswers = await Pregunta.correct_answers;
                    }
                    else{
                        throw new Error("Debes Elegir al menos una respuesta correcta ");
                    }
                }
            }


            let puntos: string = this.state.puntos.toString();
            let dtQuestionObj: any = await this.getEvaluations();
            dtQuestionObj = dtQuestionObj.data[0];

            for (let i = 0; i < dtQuestionObj.questionnaire[0].questions.length; i++) {
                if (dtQuestionObj.questionnaire[0].questions[i]._id === this.state.idPregunta) {
                    dtQuestionObj.questionnaire[0].questions[i].question = await this.state.titleQuestion;
                    dtQuestionObj.questionnaire[0].questions[i].response_type = await this.state.TipoRespuesta;
                    dtQuestionObj.questionnaire[0].questions[i].evaluation = await this.state.visibleRespuestasCorrectas;
                    dtQuestionObj.questionnaire[0].questions[i].correct_answers = await arrayCorrectAnswers;
                    dtQuestionObj.questionnaire[0].questions[i].points = await parseInt(puntos);
                    dtQuestionObj.questionnaire[0].questions[i].explanation = await this.state.explicacionRespuesta;
                    dtQuestionObj.questionnaire[0].questions[i].mandatory = await this.state.obligatorio;
                }
            }

            SaveOptions(
                this.getEvaluations,
                this.state.idPregunta, 
                this.state.visibleRespuestasCorrectas,
                arrayCorrectAnswers,
                parseInt(puntos).toString(),
                this.state.titleQuestion,
                this.state.TipoRespuesta,
                this.state.explicacionRespuesta,
                this.state.obligatorio,
                this.updateEvaluations,
                this.GetActualQuestions
            )
            halcoloaderRemove();
        }
        catch (error: any) {
            halcoloaderRemove();
            notify(error, 'error');
        }
    }

    valueChangetext = (e: any, tb: string) => {
        if (tb === "pregunta") {
            this.setState({ titleQuestion: e });
        }
        else if (tb === "puntos") {
            this.setState({ puntos: e });
        }
        else if (tb === "explicacion") {
            this.setState({ explicacionRespuesta: e });
        }
    }

    changeObligatorio = (e: any) => {
        this.setState({ obligatorio: e.value });
    }

    renderPopup = () => {
        let ableSwitch: boolean = this.state.visibleRespuestasCorrectas;
        let ableObligatorio: boolean = this.state.obligatorio;
        let points: number = this.state.puntos;
        let explanation: string = this.state.explicacionRespuesta;
        let opcionesCorrectas: any = this.state.OpcionCorrecta;
        return (
            <div className='part1'>
                <div style={{ display: "flex" }} className='part2'>
                    <div style={{ width: "50rem" }}>
                        <label>{this.state.idPregunta}</label>
                        <div style={{ margin: '1rem auto 2rem 1rem', color: "#172b4d" }}>
                            <i className="dx-icon dx-icon-alignjustify" style={{ fontSize: "1.5rem" }}></i><label style={{ fontSize: "1.3rem", margin: "auto auto auto 15px" }}>Descripción</label>
                        </div>
                        <div style={{ margin: 'auto auto auto 3.2rem' }}>
                            <TextArea
                                className='Campo'
                                label='Pregunta'
                                maxLength={"15rem"}
                                autoResizeEnabled={true}
                                onValueChange={(e) => this.valueChangetext(e, "pregunta")}
                                value={this.state.titleQuestion}
                            />
                        </div>
                        <div style={{ margin: '4rem auto 2rem 1rem', color: "#172b4d" }}>
                            <i className="dx-icon dx-icon-check" style={{ fontSize: "1.5rem" }}></i><label style={{ fontSize: "1.3rem", margin: "auto auto auto 15px" }}>Respuestas</label>
                        </div>
                        <div style={{ margin: 'auto auto 3.2rem 3.2rem' }}>
                            {this.state.Opcion}
                            {this.state.visibleNuevaOpcion &&
                                <span className='btnNuevaOpcion' onClick={() => this.AgregarOpcion(this.state.TipoRespuesta)}><i className='dx-icon dx-icon-plus iconNuevo'></i>Nueva respuesta</span>}
                        </div>
                        {this.state.visibleNuevaOpcion &&
                            <div className="dx-field">
                                <div className="dx-field-label labels"><label>¿La pregunta tiene respuestas correctas?</label></div>
                                <div className="dx-field-label">
                                    <Switch
                                        className='swObligatorio'
                                        value={ableSwitch}
                                        onValueChanged={(e) => {
                                            console.log("a")
                                            this.valueSwitchChanged(e)
                                            this.setState({ visibleRespuestasCorrectas: e.value });
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        {this.state.visibleRespuestasCorrectas &&
                            <div>
                                <div style={{ margin: '4rem auto 2rem 1rem', color: "#172b4d" }}>
                                    <i className="dx-icon dx-icon-taskcomplete" style={{ fontSize: "1.5rem" }}></i><label style={{ fontSize: "1.3rem", margin: "auto auto auto 15px" }}>Respuestas correctas</label>
                                </div>
                                <div style={{ margin: 'auto auto auto 3.2rem' }}>
                                    {opcionesCorrectas}
                                </div>
                                <TextBox
                                    label='Puntos'
                                    value={points.toString()}
                                    mask={'##'}
                                    maskRules={{ '0': /\d/ }}
                                    width={"20%"}
                                    style={{ margin: '0px 0px 0px 60px' }}
                                    onValueChange={(e) => this.valueChangetext(e, "puntos")}
                                ></TextBox>
                                <TextArea
                                    className=''
                                    label='Explicación de la respuesta'
                                    value={explanation}
                                    maxLength={"15rem"}
                                    style={{ margin: '30px 0px 0px 60px' }}
                                    autoResizeEnabled={true}
                                    onValueChange={(e) => this.valueChangetext(e, "explicacion")}
                                />
                            </div>
                        }
                    </div>
                    <div style={{ width: "27rem" }}>
                        <div style={{ margin: '1rem auto 2rem 2rem', color: "#172b4d" }}>
                            <label style={{ fontSize: "1.3rem", margin: "auto auto auto 15px" }}>Tipo de respuesta</label>
                        </div>
                        <div style={{ margin: 'auto auto auto 3.2rem' }}>
                            <span
                                className='btnTipoRespuesta'
                                onClick={() => this.ContextualComponent("text", "change")}
                            ><i className='dx-icon dx-icon-textdocument btnIcon'></i> Texto</span><br />
                            <span
                                className='btnTipoRespuesta'
                                onClick={() => this.ContextualComponent("numeric", "change")}
                            ><i className='dx-icon btnIcon numerosIcon'>123 </i> Número</span><br />
                            <span
                                className='btnTipoRespuesta'
                                onClick={() => this.ContextualComponent("opcm", "change")}
                            ><i className='dx-icon dx-icon-unselectall btnIcon'></i> Opciones (Selección múltiple)</span><br />
                            <span
                                className='btnTipoRespuesta'
                                onClick={() => this.ContextualComponent("opcu", "change")}
                            ><i className='dx-icon dx-icon-isblank btnIcon'></i> Opciones (Selección única)</span>
                            <span
                                className='btnTipoRespuesta'
                                onClick={() => this.ContextualComponent("file", "change")}
                            ><i className='dx-icon dx-icon-folder btnIcon'></i> Archivo</span>
                        </div>
                    </div><br />
                </div>
                <div className='obligatiorio'>
                    <Switch
                        className='swObligatorio'
                        value={ableObligatorio}
                        onValueChanged={(e) => { this.changeObligatorio(e) }}
                    />
                    <div className="dx-field-label labels"><label>obligatorio</label></div>
                    <Button className='btnGuardar' onClick={() => this.SaveOptions()}>Guardar</Button>
                </div>
            </div>
        );
    }

    EditPregunta = async (dataRow: any, _id: string) => {
        halcoloaderActive();
        await this.setState({ idPregunta: _id });
        await this.setState({ titleQuestion: dataRow.question });

        await this.setState({ visibleRespuestasCorrectas: dataRow.evaluation });
        if (dataRow.response_type === "opcm" || dataRow.response_type === "opcu") {
            await this.setState({ opcionesOPCM: dataRow.answers });
            await this.setState({ opcionesOPCU: dataRow.answers });
            await this.setState({ visibleNuevaOpcion: true });
            await this.setState({ puntos: dataRow.points });
            await this.setState({ explicacionRespuesta: dataRow.explanation });
            this.valueSwitchChanged(dataRow.evaluation);
            this.setState({ visibleRespuestasCorrectas: dataRow.evaluation });
        }

        await this.setState({ obligatorio: dataRow.mandatory });
        await this.ContextualComponent(dataRow.response_type, "edit");
        await this.setState({ visiblePopUp: true });
        halcoloaderRemove();
    }

    CorrectItemRender = (correctAnswers: any, option: any) => {
        let textColor: string = '#000'
        for (let i = 0; i < correctAnswers.length; i++) {
            if (correctAnswers[i]._id === option._id) textColor = '#008000';
        }
        return (<label style={{ color: textColor }}>{option.option}</label>);
    }

    response_type = (props: any, readOnly: boolean) => {
        let returnInput: any = null;
        switch (props.response_type) {
            case "text":
                returnInput = <label>Texto</label>
                break
            case "numeric":
                returnInput = <label>Número</label>
                break
            case "opcm":
                let OPCM = Object.values(props.answers);
                returnInput = <div>
                    <List
                        className='listasOpciones'
                        disabled={readOnly}
                        style={{ opacity: "1", display: "flex", Width: "150px" }}
                        visible={true}
                        dataSource={OPCM}
                        showSelectionControls={true}
                        selectionMode={"multiple"}
                        selectByClick={true}
                        displayExpr={"option"}
                        keyExpr={"_id"}
                        itemRender={(e) => this.CorrectItemRender(props.correct_answers, e)}
                    ></List>
                </div>
                break
            case "opcu":
                let OPCU = Object.values(props.answers);
                returnInput = <div>
                    <RadioGroup
                        className='listasOpciones'
                        disabled={readOnly}
                        style={{ opacity: "1", display: "flex", Width: "150px" }}
                        visible={true}
                        dataSource={OPCU}
                        id="radio-group-with-selection"
                        displayExpr={"option"}
                        valueExpr={"_id"}
                        itemRender={(e) => this.CorrectItemRender(props.correct_answers, e)}
                    ></RadioGroup>
                </div>
                break
            case "file":
                returnInput = <label>Archivo</label>
                break
        }

        return returnInput
    }

    DropdownButtonRender = (e: any) => {
        const icon: string = `dx-icon dx-icon-${e.icon}`;
        let stylelabel: any = {
            margin: "auto auto auto 1rem",
            color: "#000"
        };

        stylelabel.color = e.id === 2 ? "#ef4444" : "#000";

        return (
            <div style={{ width: "0rem", height: "0rem" }}>
                <i className={icon} style={{ fontSize: "20px" }}></i>
                <label style={stylelabel}>{e.name}</label>
            </div>
        )

    }

    onItemClick = async (e: any, propsRow: any) => {
        halcoloaderActive();
        if (e === 1) {
            var duplicateValue = await this.state.allquestions.filter((item: any) => item._id === propsRow._id);
            var NewValues = await this.state.allquestions;
            duplicateValue[0]._id = "";
            NewValues.push(duplicateValue[0]);
            await this.setState({ allquestions: NewValues });

            let evaluation: any = await this.getEvaluations();
            evaluation.data[0].questionnaire[0].questions = this.state.allquestions;

            await this.updateEvaluations(evaluation.data[0]);

            evaluation = await this.getEvaluations();
            await this.setState({ allquestions: evaluation.data[0].questionnaire[0].questions });
            notify("Pregunta dupicada", "succes");
            this.GetActualQuestions();
        }
        else if (e === 2) {
            let NewValues = await this.state.allquestions.filter((item: any) => item._id !== propsRow._id);
            await this.setState({ allquestions: NewValues });

            let evaluation: any = await this.getEvaluations();
            evaluation.data[0].questionnaire[0].questions = this.state.allquestions;

            await this.updateEvaluations(evaluation.data[0]);

            evaluation = await this.getEvaluations();
            await this.setState({ allquestions: evaluation.data[0].questionnaire[0].questions });
            notify("Pregunta eliminada", "succes");
            this.GetActualQuestions();
        }
        halcoloaderRemove();
    }

    GetActualQuestions = async () => {
        let dtResult = await this.getEvaluations();
        dtResult = dtResult.data[0].questionnaire[0].questions;
        this.setState({ ComponentPregunta: this.Preguntas(dtResult) });
    }

    Preguntas = (actualdata = null) => {
        halcoloaderActive();
        var ColletionQuestions: any = [];
        let Questions = Fnctn.NullUndefinedEmpty(actualdata) ? this.state.allquestions : actualdata;
        for (let i = 0; i < Questions.length; i++) {
            let Pregunta =
                <div>
                    <div className='cardPregunta' style={{ display: "flex" }}>
                        <div className="cardPregunta-content" onClick={() => this.EditPregunta(Questions[i], Questions[i]._id)} style={{ padding: "1% 35% 1% 1%" }}>
                            {Questions[i].mandatory && <label className='icon-Obligatorio' style={{ color: "red", fontSize: "12px" }}>*Obligatorio</label>}
                            <b><label>{Questions[i].question} </label></b>
                            {this.response_type(Questions[i], true)}
                        </div>
                        <div style={{ marginLeft: "auto" }} className='DDBOptions'>
                            <DropDownButton
                                className='menuQuestions'
                                splitButton={false}
                                useSelectMode={false}
                                icon="overflow"
                                text=''
                                showArrowIcon={false}
                                items={this.ItemsMenu}
                                displayExpr="name"
                                keyExpr="id"
                                dropDownOptions={{ width: "8rem" }}
                                onItemClick={(e) => this.onItemClick(e.itemData.id, Questions[i])}
                                itemRender={(e: any) => this.DropdownButtonRender(e)}
                            />
                        </div>
                    </div>
                </div>

            ColletionQuestions.push(Pregunta);
            halcoloaderRemove();
        }

        return (ColletionQuestions);
    }

    render() {
        return (
            <div>
                {this.state.ComponentPregunta}
                <Popup
                    width={"80rem"}
                    height={"53rem"}
                    className='winEditarPregunta'
                    showTitle={false}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    visible={this.state.visiblePopUp}
                    onHiding={this.handlePopupHidden}
                    contentRender={() => this.renderPopup()}
                    showCloseButton={false}
                    resizeEnabled={false}
                />
            </div>
        );
    }
}

export default PreguntasBox;