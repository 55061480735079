import  * as Actions  from './NF_actions'

export interface NF_State {
    id_Form: string,
    name: string,
    questions: []
}

const initialState: NF_State = {
    id_Form: "",
    name: "",
    questions: []
}

const NF_reducer = (state = initialState, action: any ) =>{
    switch(action.type){
        case Actions.SET_FORM_ID: return {...state, id_Form: action.payload}
        case Actions.SET_FORM_NAME: return {...state, name: action.payload}
        case Actions.SET_FORM_QUESTIONS: return {...state, questions: action.payload}
        default:
            return state
    }
}

export default NF_reducer
