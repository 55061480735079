import { Component } from 'react'
import './view_EvaluacionTarea.scss'
import { RootState } from '../../../../reduxStore/reducers'
import { connect } from 'react-redux'
import { ActualizarEstatusTarea, GuardarResultadoEvaluacion, ObtenerCuestionarioTarea, ObtenerEvaluacionxUsuario, DeleteEvaluationID } from '../../../../api/DataOnBoarding'
import { Navigate, Link } from 'react-router-dom'
import { Button, FileUploader, List, LoadIndicator, Popup, RadioGroup } from 'devextreme-react'
import notify from 'devextreme/ui/notify'
import { getCookie, NullUndefinedEmpty } from '../../../../contexts/GenericFunctions'
import { halcoloaderActive, halcoloaderRemove } from '../../../../contexts/HalcoLoader'
import { DeleteFile, DescargarArchivo, enumPath, uploadFiles } from '../../../../contexts/FirebaseActions'

interface IProps {
    IdEvaluacion: string
    NombreTareaSeleccionada: string
    IdTareaSeleccionada: string
    IdSegmentacion: string
}

interface IState {
    Loading?: any
    NavTarea?: any
    NavCategoria?: any
    Show_confirm_exit?: any
    Show_confirm_send?: any
    Show_confirm_NewTry?: any
    Show_confirm_LastTry?: any
    Evaluacion_deshabilitada?: any
    IdEvaluacion?: any
    ListaPreguntas?: Array<any>
    ListaRespuestas?: Array<any>
    ListaPreguntasRequeridas?: Array<any>
    ListaRespuestasIncorrectas?: Array<any>
    ListaRespuestasCorrectas?: Array<any>
    PuntosTotales: any,
    PuntosObtenidos: any,
    valoresInputs: any,
    dtFileAnswer: any,
    msjCalificacion: boolean,
    msjEncuesta: boolean
}

const renderOpcion = (data: any) => { return (<div style={{ display: "flex", whiteSpace: "normal"}}>{data.option}</div>) }

class view_EvaluacionTarea extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            Loading: false,
            NavTarea: false,
            NavCategoria: false,
            Show_confirm_exit: false,
            Show_confirm_send: false,
            Show_confirm_NewTry: false,
            Evaluacion_deshabilitada: false,
            Show_confirm_LastTry: false,
            IdEvaluacion: this.props.IdEvaluacion,
            ListaPreguntas: [],
            ListaRespuestas: [],
            ListaRespuestasIncorrectas: [],
            ListaRespuestasCorrectas: [],
            PuntosTotales: 0,
            PuntosObtenidos: 0,
            valoresInputs: "",
            dtFileAnswer: [],
            msjCalificacion: false,
            msjEncuesta: false
        }
    }

    componentDidMount(): void {
        this.ConsultarContenidoEvaluacion();
        this.reiniciarValores();
    }

    ConsultarContenidoEvaluacion = async () => {
        halcoloaderActive()

        this.setState({ ListaPreguntas: [], ListaRespuestas: [] })

        let res = await ObtenerCuestionarioTarea(this.props.IdEvaluacion)
        let preguntas = res.data[0].questionnaire[0].questions

        this.setState({ ListaPreguntas: preguntas, Loading: false })
        halcoloaderRemove()
    }

    ResponderPregunta = async (pregunta: any, respuesta: any) => {
        let TipoPregunta = pregunta['response_type']
        let arrayRespuestas = this.state.ListaRespuestas

        switch (TipoPregunta) {
            case 'text':
                if (respuesta === '') {
                    arrayRespuestas = arrayRespuestas?.filter((r: any) => r.question_id !== pregunta['_id'])
                    break;
                }

                let ObjRespuesta_t = {
                    question_id: pregunta['_id'],
                    answers: [{ '_id': '', 'option': respuesta, 'attachments': [] }],
                    points: null
                }

                let res_t = arrayRespuestas?.filter((e: any) => e.question_id === pregunta['_id'])

                if (res_t!.length > 0) {
                    arrayRespuestas?.map((e: any) => {
                        if (e.question_id === ObjRespuesta_t.question_id) {
                            e.answers = ObjRespuesta_t.answers
                        }
                    })
                } else {
                    arrayRespuestas?.push(ObjRespuesta_t)
                }

                break;
            case 'numeric':
                if (respuesta === '') {
                    arrayRespuestas = arrayRespuestas?.filter((r: any) => r.question_id !== pregunta['_id'])
                    break;
                }

                let ObjRespuesta_n = {
                    question_id: pregunta['_id'],
                    answers: [{ '_id': '', 'option': respuesta, 'attachments': [] }],
                    points: null
                }

                let res_n = arrayRespuestas?.filter((e: any) => e.question_id === pregunta['_id'])

                if (res_n!.length > 0) {
                    arrayRespuestas?.map((e: any) => {
                        if (e.question_id === ObjRespuesta_n.question_id) {
                            e.answers = ObjRespuesta_n.answers
                        }
                    })
                } else {
                    arrayRespuestas?.push(ObjRespuesta_n)
                }

                break;
            case 'opcu':
                let ObjRespuesta_u: any = {
                    question_id: pregunta['_id'],
                    answers: [respuesta.value],
                    points: 0
                }

                ObjRespuesta_u.answers[0].attachments = [];
                let res_u = arrayRespuestas?.filter((e: any) => e.question_id === pregunta['_id'])
                if (res_u!.length > 0) {
                    arrayRespuestas?.map((e: any) => {
                        if (e.question_id === ObjRespuesta_u.question_id) {
                            e.answers = ObjRespuesta_u.answers
                        }
                    })
                } else {
                    arrayRespuestas?.push(ObjRespuesta_u);
                }

                break;
            case 'opcm':
                let ObjRespuesta_m: any = {
                    question_id: pregunta['_id'],
                    answers: respuesta.addedItems.length > 0 ? [respuesta.addedItems[0]] : [],
                    points: 0
                }

                let resp = arrayRespuestas?.filter((data: any) => data.question_id === pregunta['_id'])

                if (resp!.length > 0) {
                    if (respuesta.removedItems.length > 0) {
                        let _respuestas = resp![0].answers
                        let newRes = _respuestas?.filter((e: any) => e._id !== respuesta.removedItems[0]['_id'])

                        resp![0].answers = newRes
                    }

                    if (respuesta.addedItems.length > 0) {

                        respuesta.addedItems[0].attachments = [];
                        resp![0].answers.push(respuesta.addedItems[0])
                    }

                    arrayRespuestas?.map((p: any) => {
                        if (p.question_id === resp![0].question_id) {
                            p = resp![0]
                        }
                    })

                    if (resp![0].answers.length === 0) {
                        arrayRespuestas = arrayRespuestas?.filter((r: any) => r.question_id !== pregunta['_id'])
                    }

                } else {
                    ObjRespuesta_m.answers[0].attachments = [];
                    arrayRespuestas?.push(ObjRespuesta_m)
                }

                break;
            case 'file':
                if (respuesta.length === 0) {
                    arrayRespuestas = arrayRespuestas?.filter((r: any) => r.question_id !== pregunta['_id'])
                    break;
                }

                const parts = respuesta[0].name.split('.');
                let extension = parts.length > 1 ? parts.pop() || '' : '';
                let res: any = await uploadFiles(respuesta, enumPath.ARCHIVOS_EVALUACIONES)
                let ObjRespuesta_f = {
                    question_id: pregunta['_id'],
                    answers: [{
                        '_id': '',
                        'option': res[0].NameFirebase,
                        'attachments': [{
                            'archive_name': respuesta[0].name,
                            'extension': extension,
                            'url': res[0].PathFireBase,
                            'id_firebase': res[0].NameFirebase
                        }]
                    }],
                    points: null
                }

                let res_f = arrayRespuestas?.filter((e: any) => e.question_id === pregunta['_id'])

                if (res_f!.length > 0) {
                    arrayRespuestas?.map((e: any) => {
                        if (e.question_id === ObjRespuesta_t.question_id) {
                            e.answers = ObjRespuesta_t.answers
                        }
                    })
                } else {
                    arrayRespuestas?.push(ObjRespuesta_f)
                }

                break;
        }

        this.setState({ ListaRespuestas: arrayRespuestas })

        arrayRespuestas?.map((r: any) => {
            r.points = r.points == null ? null : this.CalificarRespuesta(r.question_id)
        })

        this.setState({ ListaRespuestas: arrayRespuestas })
    }

    FinalizarCuestionario = () => {
        this.VerificarLlenosRequeridos()
    }

    VerificarLlenosRequeridos = () => {
        let PreguntasRequeridas = this.state.ListaPreguntas?.filter((pr: any) => pr.mandatory)
        let ListaRespuestas = this.state.ListaRespuestas

        let ListaRequeridas: Array<any> = []
        PreguntasRequeridas?.map((pr: any) => {
            if (ListaRespuestas?.some((r: any) => r.question_id === pr._id)) {
                ListaRequeridas.push(pr._id)
            }
        })

        if (ListaRequeridas?.length === PreguntasRequeridas?.length) {
            this.setState({ Show_confirm_send: false });
            this.CalificarRespuestas();
            this.EnviarResultados();
        } else {
            notify(
                {
                    message: "No ha contestado todas las preguntas requeridas",
                    width: 230,
                    position: {
                        at: "bottom",
                        my: "bottom",
                        of: "#container"
                    }
                },
                'error',
                3000
            )
        }
    }

    CalificarRespuesta = (question_id: any) => {
        let Preguntas = this.state.ListaPreguntas
        let Respuestas = this.state.ListaRespuestas

        let resultado = true;

        let PreguntasSeleccion = Preguntas?.filter((p: any) => p._id === question_id)

        PreguntasSeleccion?.forEach((pregunta: any) => {
            let RespuestasCorrectas = pregunta.correct_answers
            let R_Preguntas = Respuestas?.filter((r: any) => r.question_id === pregunta._id)
            let RespuestasPregunta = R_Preguntas!.length > 0 ? R_Preguntas![0].answers : []

            if (RespuestasPregunta!?.length > 0) {
                if (RespuestasCorrectas.length === RespuestasPregunta?.length) {

                    RespuestasCorrectas?.map((rc: any) => {
                        if (!RespuestasPregunta?.some((rp: any) => rp._id === rc._id)) {
                            resultado = false
                        }
                    })
                } else {
                    resultado = false
                }
            } else {
                resultado = false
            }
        })

        let points = 0
        Preguntas?.map((p: any) => {
            if (p._id === question_id) {
                points = resultado ? p.points : 0
            }
        })

        return points;
    }

    CalificarRespuestas = () => {
        let Preguntas = this.state.ListaPreguntas
        let Respuestas = this.state.ListaRespuestas

        let PreguntasCorrectas: Array<any> = []
        let PreguntasIncorrectas: Array<any> = []

        let PreguntasSeleccion = Preguntas?.filter((p: any) => ('opcm, opcu').includes(p.response_type))

        PreguntasSeleccion?.forEach((pregunta: any) => {
            let RespuestasCorrectas = pregunta.correct_answers
            let R_Preguntas = Respuestas?.filter((r: any) => r.question_id === pregunta._id)
            let RespuestasPregunta = R_Preguntas!.length > 0 ? R_Preguntas![0].answers : []

            if (RespuestasPregunta!?.length > 0) {
                if (RespuestasCorrectas.length === RespuestasPregunta?.length) {
                    let R_Correctas = true

                    RespuestasCorrectas?.map((rc: any) => {
                        if (!RespuestasPregunta?.some((rp: any) => rp._id === rc._id)) {
                            R_Correctas = false
                        }
                    })

                    if (R_Correctas)
                        PreguntasCorrectas.push({ 'question_id': pregunta._id })
                    else
                        PreguntasIncorrectas.push({ 'question_id': pregunta._id })

                } else {
                    PreguntasIncorrectas.push({ 'question_id': pregunta._id })
                }
            } else {
                PreguntasIncorrectas.push({ 'question_id': pregunta._id })
            }

        })

        // let points_evaluacion = 0
        // let points_obtenidos = 0

        // Preguntas?.map((p: any) => {
        //     if (PreguntasCorrectas?.some((pc: any) => pc.question_id == p._id)) {
        //         points_obtenidos += p.points
        //     }
        //     points_evaluacion += p.points
        // })

        this.setState({ ListaRespuestasCorrectas: PreguntasCorrectas })
        this.setState({ ListaRespuestasIncorrectas: PreguntasIncorrectas })
    }

    ObtenerRespuestas = (final: any = false) => {
        let { ListaPreguntas, ListaRespuestas } = this.state

        let ListaPreguntasSinRespuesta: Array<any> = []

        ListaPreguntas?.map((p: any) => {
            if (!ListaRespuestas?.some((r: any) => r.question_id === p._id)) {
                if (('opcu, opcm').includes(p.response_type))
                    ListaPreguntasSinRespuesta.push({
                        question_id: p._id,
                        answers: [],
                        points: 0,
                    })
                else
                    ListaPreguntasSinRespuesta.push({
                        question_id: p._id,
                        answers: [],
                        points: null
                    })
            }
        })

        let auxArreglo = ListaRespuestas?.concat(ListaPreguntasSinRespuesta)
        let PuntosTotales: number = 0;
        let PuntosObtenidos: number = 0;

        ListaPreguntas?.forEach((element: any) => { if (NullUndefinedEmpty(element.points)) { PuntosTotales += 0; } else { PuntosTotales += element.points; } });
        ListaRespuestas?.forEach((element: any) => { if (NullUndefinedEmpty(element.points)) { PuntosObtenidos += 0; } else { PuntosObtenidos += element.points; } });
        this.setState({ PuntosTotales: PuntosTotales, PuntosObtenidos: PuntosObtenidos });
        if (PuntosTotales > 0) {
            this.setState({ msjCalificacion: true });
            this.setState({ msjEncuesta: false });
        }
        else {
            this.setState({ msjCalificacion: false });
            this.setState({ msjEncuesta: true });
        }
        // final = true
        // if (final === false) {
        //     this.setState({
        //         Show_confirm_NewTry: false,
        //         ListaRespuestasIncorrectas: [],
        //         ListaRespuestasCorrectas: []
        //     });
        // }
        // else {
        this.CalificarRespuestas();
        this.setState({ Evaluacion_deshabilitada: true });
        this.RenderPreguntas();
        // }
        return auxArreglo
    }

    EnviarResultados = async () => {
        halcoloaderActive();
        this.setState({ Evaluacion_deshabilitada: true });
        let { IdEvaluacion } = this.state
        let idUsuario = getCookie()._id
        let respuestas = await this.ObtenerRespuestas(false);

        let ObjResultado = {
            evaluation_id: IdEvaluacion,
            user_id: idUsuario,
            answers_register: respuestas
        }

        await GuardarResultadoEvaluacion(ObjResultado);
        let ObjEvaluaciones = { "evaluation_id": this.state.IdEvaluacion }
        let Evaluations = await ObtenerEvaluacionxUsuario(ObjEvaluaciones);

        // if (Evaluations.data.length <= 1) {
        //     this.setState({ Show_confirm_NewTry: false });
        // }
        // else {
        // let puntuacionMenor = 1000, id_evaluacionMenor = '';
        // let archivos:any[] = [];
        // let PuntosObtenidos: number = 0;

        this.ObtenerRespuestas(true);

        // Evaluations.data.forEach((element: any) => {
        //     let puntuacionActual = 0;
        //     for (let i = 0; i < element.answers_register.length; i++) {
        //         puntuacionActual += element.answers_register[i].points;
        //     }

        //     if (puntuacionActual < puntuacionMenor) {
        //         archivos = []
        //         puntuacionMenor = puntuacionActual;
        //         id_evaluacionMenor = element._id;
        //         element.answers_register.forEach((elementFiles:any) =>{
        //             for(let i = 0; i < elementFiles.answers.length; i++){
        //                 for(let j = 0; j < elementFiles.answers[i].attachments.length; j++){
        //                     archivos.push(elementFiles.answers[i].attachments[j].id_firebase)
        //                 }
        //             }
        //         });
        //     }
        // });

        // let MejorEvaluacion = Evaluations.data.filter((item:any) => item._id !== id_evaluacionMenor);
        // MejorEvaluacion[0].answers_register?.forEach((element: any) => { if (NullUndefinedEmpty(element.points)) { PuntosObtenidos += 0; } else { PuntosObtenidos += element.points; } });

        // this.setState({ PuntosObtenidos: PuntosObtenidos });
        // archivos.forEach((id_FileFireBase:any) => { DeleteFile(id_FileFireBase, enumPath.ARCHIVOS_EVALUACIONES) });
        // await DeleteEvaluationID({ "evaluation_id": id_evaluacionMenor });
        await ActualizarEstatusTarea(this.props.IdSegmentacion, 'Completed');
        // }
        notify(
            {
                message: "Resultados enviados correctamente",
                width: 230,
                position: {
                    at: "bottom",
                    my: "bottom",
                    of: "#container"
                }
            },
            'success',
            3000
        )
        halcoloaderRemove();
        this.setState({ Show_confirm_LastTry: true });
    }

    reiniciarValores = () => {
        this.setState({ valoresInputs: {} });
    }

    manejarCambioInput = (rowData: any, valor: any) => {
        this.setState((prevState: any) => ({
            valoresInputs: {
                ...prevState.valoresInputs,
                [rowData._id]: valor,
            },
        }));
        this.ResponderPregunta(rowData, valor);
    }

    RenderPreguntas = () => {
        let { ListaRespuestasIncorrectas, ListaRespuestasCorrectas, ListaRespuestas, Evaluacion_deshabilitada, msjEncuesta } = this.state

        const pregunta_requerida = (rowData: any) => (
            rowData['mandatory']
                ?
                ListaRespuestas?.some((r: any) => r.question_id === rowData['_id'])
                    ?
                    true
                    :
                    false
                :
                true
        )

        const resultado_pregunta = (rowData: any) => (
            ListaRespuestasIncorrectas!.length === 0 && ListaRespuestasCorrectas!.length === 0 || msjEncuesta === true
                ?
                'TarjetaPregunta'
                :
                ListaRespuestasIncorrectas?.some((r: any) => (rowData['_id']).includes(r.question_id))
                    ?
                    'TarjetaPreguntaIncorrecta'
                    :
                    'TarjetaPreguntaCorrecta'
        );

        return (
            this.state.ListaPreguntas?.map((rowData, index) => (
                rowData['response_type'] === 'text' ?
                    <div key={index} className={'TarjetaPregunta'}>
                        <p className='Pregunta'>{rowData['question']} <label style={{ color: 'red' }}>{rowData['mandatory'] ? '*' : ''}</label></p>
                        <div className='divRespuestaTexto'>
                            <input
                                disabled={Evaluacion_deshabilitada}
                                className='RespuestaTexto'
                                type='text'
                                value={this.state.valoresInputs[rowData._id] || ''}
                                onBlur={(e) => this.ResponderPregunta(rowData, e.target.value)}
                                onChange={(e) => this.manejarCambioInput(rowData, e.target.value)}
                            />
                        </div>
                        <label hidden={pregunta_requerida(rowData)} style={{ color: 'red' }}>Es requerida esta pregunta</label>
                    </div>
                    : rowData['response_type'] === 'numeric' ?
                        <div key={index} className='TarjetaPregunta'>
                            <p className='Pregunta'>{rowData['question']} <label style={{ color: 'red' }}>{rowData['mandatory'] ? '*' : ''}</label></p>
                            <div className='divRespuestaTexto'>
                                <input
                                    disabled={Evaluacion_deshabilitada}
                                    className='RespuestaTexto'
                                    value={this.state.valoresInputs[rowData._id] || ''}
                                    type='number'
                                    onBlur={(e) => this.ResponderPregunta(rowData, e.target.value)}
                                    onChange={(e) => this.manejarCambioInput(rowData, e.target.value)}
                                />
                            </div>
                            <label hidden={pregunta_requerida(rowData)} style={{ color: 'red' }}>Es requerida esta pregunta</label>
                        </div>
                        : rowData['response_type'] === 'opcu' ?
                            <div key={index} className={resultado_pregunta(rowData)}>
                                <p className='Pregunta'>{rowData['question']} <label style={{ color: 'red' }}>{rowData['mandatory'] ? '*' : ''}</label></p>
                                <div className=''>
                                    <RadioGroup
                                        disabled={Evaluacion_deshabilitada}
                                        items={rowData['answers']}
                                        itemRender={renderOpcion}
                                        onValueChanged={(e) => this.ResponderPregunta(rowData, e)}
                                    />
                                </div>
                                <label hidden={pregunta_requerida(rowData)} style={{ color: 'red' }}>Es requerida esta pregunta</label>
                            </div>
                            : rowData['response_type'] === 'opcm' ?
                                <div key={index} className={resultado_pregunta(rowData)}>
                                    <p className='Pregunta'>{rowData['question']} <label style={{ color: 'red' }}>{rowData['mandatory'] ? '*' : ''}</label></p>
                                    <div className=''>
                                        <List
                                            disabled={Evaluacion_deshabilitada}
                                            items={rowData['answers']}
                                            showSelectionControls={true}
                                            selectionMode={"multiple"}
                                            selectByClick={true}
                                            displayExpr={"option"}
                                            keyExpr={"_id"}
                                            itemRender={renderOpcion}
                                            onSelectionChanged={(e) => this.ResponderPregunta(rowData, e)}
                                        />
                                    </div>
                                    <label hidden={pregunta_requerida(rowData)} style={{ color: 'red' }}>Es requerida esta pregunta</label>
                                </div>
                                : rowData['response_type'] === 'file' ?
                                    <div key={index} className={'TarjetaPregunta'}>
                                        <div className=''>
                                            <p className='Pregunta'>{rowData['question']} <label style={{ color: 'red' }}>{rowData['mandatory'] ? '*' : ''}</label></p>
                                            <FileUploader
                                                accept='*'
                                                onValueChanged={(files) => this.archivo(files, rowData)}
                                                disabled={Evaluacion_deshabilitada}
                                                value={rowData['answers']}
                                            />
                                        </div>
                                        <label hidden={pregunta_requerida(rowData)} style={{ color: 'red' }}>Es requerida esta pregunta</label>
                                    </div>
                                    :
                                    <div></div>
            ))
        )
    }

    archivo = async (files: any, rowData: any) => {
        let archivos = files.value
        this.ResponderPregunta(rowData, archivos)
    }

    deletearchivo = async () => {
        await DeleteFile("d2b7e8fd-ca4b-42c6-b8b4-c79940afe084_HF__image_2024_04_30T22_49_36_494Z.png", enumPath.ICONOS_CATEGORIAS)
    }

    descargararchivo = async () => {
        await DescargarArchivo('081e3d75-3002-4e87-a54d-d617389bd06f_HF__image_2024_04_30T20_19_16_583Z.png', enumPath.ICONOS_CATEGORIAS)
    }

    NuevoIntento = () => {
        this.ConsultarContenidoEvaluacion();
        this.setState({
            Show_confirm_NewTry: false,
            ListaRespuestasIncorrectas: [],
            ListaRespuestasCorrectas: [],
            ListaRespuestas: [],
            Evaluacion_deshabilitada: false
        })
        this.RenderPreguntas();
        this.reiniciarValores()
    }

    render() {
        const { Show_confirm_exit, Show_confirm_send, Show_confirm_NewTry, Evaluacion_deshabilitada, PuntosObtenidos, PuntosTotales, msjCalificacion, msjEncuesta } = this.state

        return (
            <div className='DivPrincipalEvaluacion'>
                <Popup
                    visible={Show_confirm_exit}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    onHiding={() => this.setState({ Show_confirm_exit: false })}
                    showCloseButton={false}
                    showTitle={false}
                    width={'auto'}
                    height={'auto'}
                >
                    <div style={{ justifyContent: 'center' }} >
                        <label style={{ fontWeight: 'bold', fontSize: 18, width: '100%' }}>¿Descartar respuestas?</label>
                        <p style={{ fontSize: 15, marginTop: 20, marginBottom: 20 }}>Se perderán todos los cambios</p>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button className='botonAceptar' onClick={() => this.setState({ NavTarea: true })}><label style={{ fontWeight: 'bold', fontSize: 13 }}>Aceptar</label></Button>
                            <Button className='botonCancelar' onClick={() => this.setState({ Show_confirm_exit: false })}><label style={{ fontWeight: 'bold', fontSize: 13 }}>Cancelar</label></Button>
                        </div>
                    </div>
                </Popup>
                <Popup
                    visible={Show_confirm_send}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    onHiding={() => this.setState({ Show_confirm_send: false })}
                    showCloseButton={false}
                    showTitle={false}
                    width={'auto'}
                    height={'auto'}
                >
                    <div style={{ justifyContent: 'center' }} >
                        <label style={{ fontWeight: 'bold', fontSize: 18, width: '100%' }}>¿Desea mandar las respuestas?</label>
                        <p style={{ fontSize: 15, marginTop: 20, marginBottom: 20 }}>No se podrán cambiar despues</p>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button className='botonAceptar' onClick={() => this.FinalizarCuestionario()}><label style={{ fontWeight: 'bold', fontSize: 13 }}>Aceptar</label></Button>
                            <Button className='botonCancelar' onClick={() => this.setState({ Show_confirm_send: false })}><label style={{ fontWeight: 'bold', fontSize: 13 }}>Cancelar</label></Button>
                        </div>
                    </div>
                </Popup>
                <Popup
                    visible={Show_confirm_NewTry}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    onHiding={() => this.setState({ Show_confirm_send: false })}
                    showCloseButton={false}
                    showTitle={false}
                    width={'auto'}
                    height={'auto'}
                >
                    <div style={{ justifyContent: 'center' }} >
                        <label style={{ fontWeight: 'bold', fontSize: 18, width: '100%' }}>Tú puntuación ha sido de {PuntosObtenidos}/{PuntosTotales}  ¿Quieres intentarlo nuevamente?</label>
                        <p>Solo tienes una oportunidad más (Se tomará en cuenta tu puntuación más alta)</p>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button className='botonAceptar' onClick={() => this.NuevoIntento()}><label style={{ fontWeight: 'bold', fontSize: 13 }}>Intentar nuevamente</label></Button>
                            <Button className='botonCancelar' onClick={async () => {
                                this.setState({ Show_confirm_NewTry: false });
                                this.ObtenerRespuestas(true);
                                await ActualizarEstatusTarea(this.props.IdSegmentacion, 'Completed')
                            }}><label style={{ fontWeight: 'bold', fontSize: 13 }}>Finalizar</label></Button>
                        </div>
                    </div>
                </Popup>
                <Popup
                    visible={this.state.Show_confirm_LastTry}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    onHiding={() => this.setState({ Show_confirm_LastTry: false })}
                    showCloseButton={false}
                    showTitle={false}
                    width={'auto'}
                    height={'auto'}
                >
                    {msjCalificacion &&
                        <div style={{ justifyContent: 'center' }} >
                            <label style={{ fontWeight: 'bold', fontSize: 18, width: '100%' }}>Tú puntuación final es de {PuntosObtenidos}/{PuntosTotales}</label><br />
                            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                <Button className='botonAceptar' onClick={() => this.setState({ Show_confirm_LastTry: false })}><label style={{ fontWeight: 'bold', fontSize: 13 }}>Continuar</label></Button>
                            </div>
                        </div>
                    }
                    {msjEncuesta &&
                        <div style={{ justifyContent: 'center' }} >
                            <label style={{ fontWeight: 'bold', fontSize: 18, width: '100%' }}>La encuesta ha sido finalizada</label><br />
                            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                <Button className='botonAceptar' onClick={() => this.setState({ Show_confirm_LastTry: false })}><label style={{ fontWeight: 'bold', fontSize: 13 }}>Continuar</label></Button>
                            </div>
                        </div>
                    }
                </Popup>
                <div className='buttonAtras2' style={{ display: 'flex', flexDirection: 'column' }} >
                    <div style={{ width: '70%', alignSelf: 'center' }}>
                        <Button onClick={() => { Evaluacion_deshabilitada ? this.setState({ NavCategoria: true }) : this.setState({ Show_confirm_exit: true }) }} className='btnAtras' icon="back" text="Atras"></Button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4 style={{ width: '70%', alignSelf: 'center', justifyContent: 'center' }}>{this.props.NombreTareaSeleccionada}</h4>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <div style={{ width: '70%', alignSelf: 'center', justifyContent: 'center' }}>
                        <label style={{ color: 'red', fontSize: 12, marginBottom: 20 }}>* Obligatorio</label>
                        {
                            this.state.Loading
                                ?
                                <LoadIndicator id="large-indicator" height={60} width={60} />
                                :
                                this.RenderPreguntas()
                        }
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '70%', alignSelf: 'center' }}>
                        <Button disabled={Evaluacion_deshabilitada} className='buttonCompletarEvaluacion' onClick={() => this.setState({ Show_confirm_send: true })}>
                            Completar
                        </Button>
                    </div>
                </div>
                {this.state.NavTarea && <Navigate to='/DetalleTarea' />}
                {this.state.NavCategoria && <Navigate to='/DetalleCategoria' />}
                {/* <FileUploader
                    accept='image/*'
                    multiple={true}
                    //onFilesUploaded={(files) => this.archivo(files)}
                    onValueChanged={(files) => this.archivo(files) }
                />

                <Button onClick={() => this.deletearchivo() }>elimina</Button>

                <Button onClick={() => this.descargararchivo()}>descargar</Button> */}
                {/* <div className='buttonAtras2' style={{ display: 'flex', flexDirection: 'row' }} > */}
                {Evaluacion_deshabilitada &&
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '70%', margin: "-5% 0% 0% 69.5%" }}>
                            <Link to={'/DetalleCategoria'}>
                                <Button className='btnContinuar' icon="next" text="Continuar"></Button>
                            </Link>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        IdEvaluacion: state.OverBoard.IdEvaluacion,
        NombreTareaSeleccionada: state.OverBoard.NombreTareaSeleccionada,
        IdTareaSeleccionada: state.OverBoard.IdTareaSeleccionada,
        IdSegmentacion: state.OverBoard.IdSegmentacion
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(view_EvaluacionTarea)
