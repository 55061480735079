export const SET_CATEGORIA_ID = "SET_CATEGORIA_ID";
export const SET_CATEGORIA_IMG = "SET_CATEGORIA_IMG"
export const SET_CATEGORIA_NOMBRE = "SET_CATEGORIA_NOMBRE"
export const SET_TAREA_SELECCIONADA = "SET_TAREA_SELECCIONADA"
export const SET_NOMBRE_TAREA_SELECIONADA = "SET_NOMBRE_TAREA_SELECIONADA"
export const SET_ID_EVALUACION = "SET_ID_EVALUACION"
export const SET_ESTATUS_TAREA = "SET_ESTATUS_TAREA"
export const SET_ID_SEGMENTACION = "SET_ID_SEGMENTACION"

export const set_categoria_id = (id: string) => {
    return{
        type: SET_CATEGORIA_ID,
        payload: id
    }
}

export const set_categoria_img = (img: string) => {
    return{
        type: SET_CATEGORIA_IMG,
        payload: img
    }
}

export const set_categoria_nombre = (nombre: string) => {
    return {
        type: SET_CATEGORIA_NOMBRE,
        payload: nombre
    }
}

export const set_tarea_seleccionada = (id: string) => {
    return {
        type: SET_TAREA_SELECCIONADA,
        payload: id
    }
}

export const set_nombre_tarea_seleccionada = (nombre: string) => {
    return {
        type: SET_NOMBRE_TAREA_SELECIONADA,
        payload: nombre
    }
}

export const set_id_Evaluacion = (id: string) => {
    return {
        type: SET_ID_EVALUACION,
        payload: id
    }
}

export const set_estatus_tarea = (estatus: string) => {
    return {
        type: SET_ESTATUS_TAREA,
        payload: estatus
    }
}

export const set_id_segmentacion = (id: string) => {
    return {
        type: SET_ID_SEGMENTACION,
        payload: id
    }
}
