import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import type { SideNavigationMenuProps } from '../../types';
import * as events from 'devextreme/events';
import { getCookie } from '../../contexts/GenericFunctions'
import './SideNavigationMenu.scss';

export default function SideNavigationMenu(props: React.PropsWithChildren<SideNavigationMenuProps>) {
  const [Menu, setMenu] = useState([])

  async function GetMenu() {
    const data = getCookie();
    setMenu(data.data_modules);
  }
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady,
  } = props;
  const { isLarge } = useScreenSize();
  function normalizePath() {
    if (Menu !== null && Menu !== undefined && Menu.length > 0) {
      return Menu.map((item: { path: string; }) => (
        { ...item, expanded: isLarge, path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path }
      ))
    }
  }

  const items = useMemo(
    normalizePath,
    [GetMenu,isLarge]
  );

  const { navigationData: { currentPath } } = useNavigation();

  const treeViewRef = useRef<TreeView>(null);
  const wrapperRef = useRef<HTMLDivElement>();
  const getWrapperRef = useCallback((element: HTMLDivElement) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', (e: React.PointerEvent) => {
      openMenu(e);
    });
  }, [openMenu]);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  useEffect(() => {
    GetMenu();
  }, []); // Ejecuta GetMenu solo una vez al montar el componenteuseEffect(() => {

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
        />
      </div>
    </div>
  );
}
