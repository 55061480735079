
import { GetAPI, getCookie } from "../contexts/GenericFunctions"

export async function ObtenerListaCategorias() {
    const cookie = getCookie()

    const api = GetAPI()
    const token = cookie != null ? cookie.token : null
    const IdUsuario = cookie != null ? cookie._id : null

    const url = `${api}/catalogs/categories/?user_id=${IdUsuario}&onboarding=true`
    const requestOptions = {
        method: 'GET',
        headers: {
          'content-type':'application/json',
          "Authorization": `Bearer ${token}` 
        },
    }

    let response = await fetch(url, requestOptions)

    return await response.json()
}


/* Obtner tareas de categorias */

export async function ObtenerTareasCategoria(IdCategoria: string) {
    const cookie = getCookie()

    const api = GetAPI()
    const token = cookie != null ? cookie.token : null
    const IdUsuario = cookie != null ? cookie._id : null

    const url = `${api}/training/onbording_users/${IdCategoria}?user_id=${IdUsuario}`
    const requestOptions = {
        method: 'GET',
        headers: {
          'content-type':'application/json',
          "Authorization": `Bearer ${token}` 
        }
    }

    let response = await fetch(url, requestOptions)
    let tareas = await response.json()

    let tareas_ordenadas =  tareas.data?.sort((a: any,b: any) => {
        if (a.order_task < b.order_task)
            return -1
        
        if (a.order_task > b.order_task)
            return 1
        
        return 0
    })

    tareas = tareas_ordenadas

    let i = 0
    tareas.map((t:any) => {
      // if (t.order_task == 0){
      //   t.TareaAnterior = {}
      // }else if(t.order_task!= 0){
      //   t.TareaAnterior = tareas[i - 1]
      // }

      if (i === 0){
        t.TareaAnterior = {}
      }else{
        t.TareaAnterior = tareas[i - 1]
      }


      i++;
    })

    //  console.log("trs", tareas)

    return [
      // {
      //     icon: 'description',
      //     title: "Todas",
      //     tasks: tareas.data
      // },
      {
        icon: 'description',
        title: 'Todas',
        tasks: tareas.filter((item: any) =>  item.status !== "Completed")
      },
      // {
      //     icon: 'description',
      //     title: 'No iniciada',
      //     tasks: tareas.data.filter((item: any) =>  item.status == "Not Started")
      // },
      {
          icon: 'taskcomplete',
          title: 'Completadas',
          tasks: tareas.filter((item: any) =>  item.status === "Completed")
      },
  ];
}

export async function ObtenerContenidoTarea(IdTarea: string) {
  const cookie = getCookie()

  const api = GetAPI()
  const token = cookie != null ? cookie.token : null

  const url = `${api}/training/task/${IdTarea}`
    const requestOptions = {
        method: 'GET',
        headers: {
          'content-type':'application/json',
          "Authorization": `Bearer ${token}` 
        }
    }

    let response = await fetch(url, requestOptions)
    let res = await response.json()

    return res
}

/* Obtener detalle de tarea y su cuestionario */
export async function ObtenerCuestionarioTarea(IdCuestionario: string) {
  const cookie = getCookie()

  const api = GetAPI()
  const token = cookie != null ? cookie.token : null

  const url = `${api}/training/evaluations/${IdCuestionario}`
  const requestOptions = {
    method: 'GET',
    headers: {
      'content-type':'application/json',
      "Authorization": `Bearer ${token}` 
    }
}

  let response = await fetch(url, requestOptions)
  let res = await response.json()

  return res
}

export async function GuardarResultadoEvaluacion(ResultadoEvaluacion: any) {
  const cookie = getCookie()

  const api = GetAPI()
  const token = cookie != null ? cookie.token : null
  let json_resEvaluacion = JSON.stringify(ResultadoEvaluacion)

  const url = `${api}/training/evaluations_answers`
  const requestOptions = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: json_resEvaluacion
  }

  let response = await fetch(url, requestOptions)
  let res = await response.json()
  console.log(res);

  return res
}

export async function ActualizarEstatusTarea(IdSegmentacion: string, Estatus: string){
  const cookie = getCookie()

  const api = GetAPI()
  const token = cookie != null ? cookie.token : null

  let obj = { 'status': Estatus }

  let json_update = JSON.stringify(obj)

  const url = `${api}/training/onbording_users/${IdSegmentacion}`
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: json_update
  }

  let response = await fetch(url, requestOptions)
  let res = await response.json()

  return res
}

export async function ObtenerEvaluacionxUsuario(dataEvaluacion:any) {
  const cookie = getCookie();
  const api = GetAPI();
  const token = cookie != null ? cookie.token : null
  const IdUsuario = cookie != null ? cookie._id : null
  dataEvaluacion.user_id = IdUsuario;

  let response = await fetch(`${api}/training/evaluations_answers?filter_dinamic=true&evaluation_id=${dataEvaluacion.evaluation_id}&user_id=${dataEvaluacion.user_id}`,
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
      // body: JSON.stringify(dataEvaluacion)
    });

  // let response = await fetch(url, requestOptions)
  let res = await response.json();
  return res
}

export async function DeleteEvaluationID(dataEvaluacion:any) {
  const cookie = getCookie();
  const api = GetAPI();
  const token = cookie != null ? cookie.token : null
  const IdUsuario = cookie != null ? cookie._id : null
  dataEvaluacion.user_id = IdUsuario;

  let response = await fetch(`${api}/training/evaluations_answers/${dataEvaluacion.evaluation_id}`,
    {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  let res = await response.json();
  return res
}