import React, { useState } from 'react';
import { Button, DataGrid } from 'devextreme-react';
import { Export, ColumnChooser, ColumnFixing, Pager, Column } from 'devextreme-react/data-grid';
import { TagBoxSucursales, TagBoxAreas, TextBoxEvaluations, CreeateHeadersGeneralForms, RenderHeader, CellRenderDtForm } from '../../components/ReportTasks/ReportTasks'
import * as Fnctn from '../../contexts/GenericFunctions'
import { DataGridTypes } from "devextreme-react/data-grid"
import 'bootstrap/dist/css/bootstrap.min.css';
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

function ReportGeneraEvaluations() {

  const [codeSucursales, setCodeSucursales] = useState<any>();
  const [codeAreas, setCodeAreas] = useState<any>();
  const [codeEvaluacion, setCodeEvaluacion] = useState<any>();
  const [dgvDatos, setDgvDatos] = useState<any>();
  const [visibleDgvDatos, setVisibleDgvDatos] = useState<boolean>();

  const getIds = (list: any, tb: string) => {
    if (tb === "sucursales") setCodeSucursales(list);
    else if (tb === "areas") setCodeAreas(list);
    else if (tb === "Evaluaciones") setCodeEvaluacion(list);
  }

  const onExporting = (e: DataGridTypes.ExportingEvent) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Seguimiento de tareas');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Respuestas_Evaluaciones.xlsx`);
      });
    });
  };

  const Consultar = async () => {
    halcoloaderActive();
    let api = Fnctn.GetAPI();
    let data = Fnctn.getCookie();

    let ObjtEvaluations: any = {
        "id_evaluacion": codeEvaluacion,
        "code_branches": codeSucursales,
        "ids_areas": codeAreas,
        "cvetra": []
    };

    let dtEvaluations:any = await fetch(`${api}/reports/medicion_evaluaciones?filter_cvetra=false`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
      body: JSON.stringify(ObjtEvaluations)
    });

    dtEvaluations = await dtEvaluations.json();

    let columns = CreeateHeadersGeneralForms(dtEvaluations.data[0]);

      setDgvDatos(
          <DataGrid
              className='dgvResultados'
              dataSource={dtEvaluations.data}
              height="650px"
              width="100%"
              rowAlternationEnabled={false}
              allowColumnReordering={true}
              allowColumnResizing={true}
              showBorders={true}
              onExporting={onExporting}
          >
              <ColumnChooser enabled={true} />
              <ColumnFixing enabled={true} />
              <Column dataField={"employee_name"} caption={'Nombre'} visible={true} width={"300px"} fixed={true}></Column>
              {
                  columns.map((column: any, index: any) => (
                      <Column
                          {...column}
                          allowSorting={true}
                          key={index}
                          customProperty={column.ResponseType}
                          headerCellRender={RenderHeader}
                          cellRender={(e) => CellRenderDtForm(e)}
                          alignment='left'
                          width={"200"}
                      />
                  ))
              }
              <Export enabled={true} allowExportSelectedData={true} />
              <Pager showPageSizeSelector={true} allowedPageSizes={dtEvaluations.data.length > 1000 ? [100, 150, 1000] : [100, 150, 'all']} />
          </DataGrid>
      );
    setVisibleDgvDatos(true);
    halcoloaderRemove();
  };

    return (
        <React.Fragment>
            <div className={'content-block dx-card responsive-paddings'} style={{ margin: "30px 0px 30px 45px", width: "95%" }}>
                <div style={{ display: "flex" }}>
                    <TextBoxEvaluations getIds={getIds}></TextBoxEvaluations>
                    {/* <SelectBox
                        className='tbEvaluaciones'
                        dataSource={Evaluations}
                        label='Evaluaciones'
                        showSelectionControls={false}
                        searchEnabled={true}
                        displayExpr={"name"}
                        valueExpr={"_id"}
                        width={"25%"}
                        // onItemClick={(e: any) => itemclick(e, "single")}
                        // onSelectAllValueChanged={(e: any) => itemclick(e, "all")}
                    ></SelectBox> */}
                    <TagBoxSucursales getIds={getIds}></TagBoxSucursales>
                    <TagBoxAreas getIds={getIds}></TagBoxAreas>
                    <Button
                        className='btnConsultar'
                        stylingMode='contained'
                        text='Consultar'
                        onClick={() => Consultar()}
                    ></Button>
                </div>
                {visibleDgvDatos && dgvDatos}
            </div>
        </React.Fragment>
    )
}

export default ReportGeneraEvaluations; 