import React, { useState, useEffect } from 'react';
import { Button, DateBox, DataGrid, Popup } from 'devextreme-react';
import { Column, Export, ColumnChooser, ColumnFixing, Pager } from 'devextreme-react/data-grid';
import { TagBoxSucursales, TagBoxAreas, CreateHeadersTasks, CellRenderProgressBar, RenderHeader, RenderCellDetail, ButtonForm, RenderDtForm } from '../../components/ReportTasks/ReportTasks'
import * as Fnctn from '../../contexts/GenericFunctions'
import { DataGridTypes } from "devextreme-react/data-grid"
import 'bootstrap/dist/css/bootstrap.min.css';
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

function ReportTask() {
  const [codeSucursales, setCodeSucursales] = useState<any>();
  const [codeAreas, setCodeAreas] = useState<any>();
  const [desde, setDesde] = useState<any>();
  const [hasta, setHasta] = useState<any>();
  const [dgvDatos, setDgvDatos] = useState<any>();
  const [dtDetail, setDtDetail] = useState<any>([]);
  const [dtDgvForm, setDtDgvForm] = useState<any>([]);
  const [visibleDgvDatos, setVisibleDgvDatos] = useState<boolean>();
  const [visibledgvDetail, setVisibledgvDetail] = useState<boolean>();
  const [visibledgvForm, setVisibledgvForm] = useState<boolean>();
  const [employeeName, setEmployeeName] = useState<string>();
  const [categoryName, setCategoryName] = useState<string>();

  const getIds = (list: any[], tb: string) => {
    if (tb === "sucursales") setCodeSucursales(list);
    else if (tb === "areas") setCodeAreas(list);
  }

  const ChangeDate = (e: any, dtb: string) => {
    const date = new Date(e);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const dateValue = `${year}-${month}-${day}`;

    if (dtb === "desde") setDesde(dateValue)
    else if (dtb === "hasta") setHasta(dateValue);
    else if (dtb === "dtpDesde") setDesde(e)
    else if (dtb === "dtpHasta") setHasta(e)
  };

  const GetInfoCategory = async (e: any) => {
    let infoColumns: string[] = ["num_employee", "name", "area", "job_name"];
    if (e.rowType === 'data') {
      if (!infoColumns.includes(e.column.dataField)) {
        halcoloaderActive();
        let api = Fnctn.GetAPI();
        let data = Fnctn.getCookie();

        let Objt: any = {
          "cvetra": [e.data.num_employee],
          "id_categoria": e.column.customProperty
        };

        const dtresult = await fetch(`${api}/reports/task_tracking_detalle`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${data.token}`
          },
          body: JSON.stringify(Objt)
        });
        const dtDetail = await dtresult.json();
        const sortedItems = await [...dtDetail.data].sort((a, b) => a.order - b.order);

        setDtDetail(sortedItems);
        setVisibledgvDetail(true);
        halcoloaderRemove();
      }
    }
  }

  const onExporting = (e: DataGridTypes.ExportingEvent) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Seguimiento de tareas');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      let desdeFecha: string = desde;
      desdeFecha = desdeFecha.replace(/-/g, "_");

      let hastaFecha: string = hasta;
      hastaFecha = hastaFecha.replace(/-/g, "_");

      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Seguimiento_de_Tareas_${desdeFecha}_a_${hastaFecha}.xlsx`);
      });
    });
  };

  const Consultar = async () => {
    halcoloaderActive();
    let api = Fnctn.GetAPI();
    let data = Fnctn.getCookie();

    let ObjtTasks: any = {
      "desde": desde,
      "hasta": hasta,
      "code_branches": codeSucursales,
      "ids_areas": codeAreas
    };

    const dtresult = await fetch(`${api}/reports/task_tracking`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
      body: JSON.stringify(ObjtTasks)
    });

    const dtTasks = await dtresult.json();

    let ObjtPorcents: any = {
      "code_branches": codeSucursales,
      "ids_areas": codeAreas
    };

    const dtresultPorcent = await fetch(`${api}/reports/evaluaciones_generales`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
      body: JSON.stringify(ObjtPorcents)
    });
    const dtPorcent = await dtresultPorcent.json();

    let columns = await CreateHeadersTasks(dtTasks.data[0]);

    dtTasks.data.forEach((obj1: any) => {
      const obj2 = dtPorcent.data.find((obj: any) => obj.num_employee === obj1.num_employee);
      if (obj2) {
        for (let i = 1; i <= columns.length; i++) {
          obj1[`promedio_evaluaciones_cat_${i}`] = obj2[`promedio_evaluaciones_cat_${i}`];
        }
      }
    });

    columns = await CreateHeadersTasks(dtTasks.data[0]);

    setDgvDatos(
      <DataGrid
        className='dgvResultados'
        dataSource={dtTasks.data}
        height="650px"
        width="100%"
        rowAlternationEnabled={false}
        allowColumnReordering={true}
        allowColumnResizing={true}
        showBorders={true}
        onCellDblClick={(e) => GetInfoCategory(e)}
        onExporting={onExporting}
      >
        <ColumnChooser enabled={true} />
        <ColumnFixing enabled={true} />
        {/*Show*/}
        <Column dataField={"num_employee"} caption='No Empleado' visible={true} fixed={true} width="100px"></Column>
        <Column dataField={"name"} caption='Nombre' visible={true} fixed={true} width="300px"></Column>
        <Column dataField={"area"} caption='Area' visible={true} fixed={true} width="200px"></Column>
        <Column dataField={"job_name"} caption={"Puesto"} visible={true} fixed={true} width="300px"></Column>
        {
          columns.map((column: any, index: any) => (
            <Column
              {...column}
              allowSorting={true}
              key={index}
              cellRender={(e) => CellRenderProgressBar(e, column.type)}
              customProperty={column.category_id}
              headerCellRender={RenderHeader}
            />
          ))
        }
        {/*Hides*/}
        <Export enabled={true} allowExportSelectedData={true} />
        <Pager showPageSizeSelector={true} allowedPageSizes={dtTasks.data.length > 1000 ? [100, 150, 1000] : [100, 150, 'all']} />
      </DataGrid>
    );
    setVisibleDgvDatos(true);
    halcoloaderRemove();
  };

  const handlePopupHidden = (popUp: string) => {
    if (popUp === "dgvDetail") setVisibledgvDetail(false);
    else if (popUp === "dgvForm") {
      setVisibledgvForm(false);
      setVisibledgvDetail(true);
    }
  }

  const SetParamsDetail = () => {
    setCategoryName(dtDetail[0].category_name);
    setEmployeeName(dtDetail[0].employee_name);
  }

  const GetDtForm = (dtResult: any, headers: any) => {
    setDtDgvForm(headers);
    setVisibledgvDetail(false);
    setVisibledgvForm(true);
  }

  const rndrDgvDetail = () => {

    return <div>
      <DataGrid
        className='dgvDetailResults'
        dataSource={dtDetail}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showBorders={true}
        width="100%"
        height={"360px"}
        allowColumnResizing={true}
      >
        <Column dataField={"task_title"} caption='Título' visible={true} width={"40%"}></Column>
        <Column dataField={"onboarding_status"} caption='Estatus' visible={true} cellRender={(e) => RenderCellDetail(e, SetParamsDetail)} alignment='center' width={"40%"}></Column>
        <Column dataField={"qualification"} caption='Promedio' visible={true} width={"auto"} format={{ type: 'percent', precision: 2 }}></Column>
        <Column cellRender={(e) => !Fnctn.NullUndefinedEmpty(e.data.evaluation_id) ?
          ButtonForm({
            "cvetra": e.data.employee_cvetra,
            "evaluation_id": e.data.evaluation_id,
            "code_branches": codeSucursales,
            "id_areas": codeAreas
          }, GetDtForm) : ""
        } caption='Evaluación' visible={true} width={"20%"} alignment='center'></Column>
        <Column dataField={"employee_name"} visible={false}></Column>
        <Column dataField={"category_name"} visible={false}></Column>
        <Column dataField={"employee_cvetra"} visible={false}></Column>
        <Column dataField={"onboarding_expiration"} visible={false}></Column>
      </DataGrid>
    </div>
  }

  const rndrDgvForm = () => {
    return <div>
      <DataGrid
        className='dgvForm'
        dataSource={dtDgvForm}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        width="100%"
        height={"680px"}
        allowColumnResizing={true}
      >
        <Column dataField={"_id"} visible={false}></Column>
        <Column dataField={"question"} caption='Pregunta' visible={true} width={"60%"} cellRender={(e) => RenderDtForm(e, "Question")}></Column>
        <Column dataField={"answer"} caption='Respuesta' visible={true} width={"40%"} cellRender={(e) => RenderDtForm(e, "Answer")}></Column>
        <Column dataField={"response_type"} caption='response_type' visible={false} width={"40%"}></Column>
      </DataGrid></div>
  }

  useEffect(() => {
    const initialDate = {
      value: new Date()
    };

    ChangeDate(initialDate.value, "desde");
    ChangeDate(initialDate.value, "hasta");
  }, []);

  return (
    <React.Fragment>
      <div className={'content-block dx-card responsive-paddings'} style={{ margin: "30px 0px 30px 45px", width: "95%" }}>
        <div style={{ display: "flex" }}>
          <DateBox
            className='dbDesde'
            label='Desde'
            type="date"
            style={{ margin: "0px 0px 0px 40px" }}
            displayFormat="yyyy-MM-dd"
            onValueChange={(e) => ChangeDate(e, "dtpDesde")}
            value={desde}
          />
          <DateBox
            className='dbHasta'
            label='Hasta'
            type="date"
            style={{ margin: "0px 0px 0px 40px" }}
            displayFormat="yyyy-MM-dd"
            onValueChange={(e) => ChangeDate(e, "dtpHasta")}
            value={hasta}
          />
          <TagBoxSucursales getIds={getIds}></TagBoxSucursales>
          <TagBoxAreas getIds={getIds}></TagBoxAreas>
          <Button
            className='btnConsultar'
            stylingMode='contained'
            text='Consultar'
            onClick={() => Consultar()}
          ></Button>
        </div>
        {visibleDgvDatos && dgvDatos}
        <Popup
          width={"50rem"}
          height={"30rem"}
          showTitle={true}
          title={`${employeeName} - ${categoryName}`}
          dragEnabled={false}
          hideOnOutsideClick={true}
          visible={visibledgvDetail}
          onHiding={() => handlePopupHidden("dgvDetail")}
          contentRender={rndrDgvDetail}
          showCloseButton={false}
          resizeEnabled={true}
        />
        <Popup
          width={"70rem"}
          height={"50rem"}
          showTitle={true}
          title={`Evaluación`}
          dragEnabled={false}
          hideOnOutsideClick={true}
          visible={visibledgvForm}
          onHiding={() => handlePopupHidden("dgvForm")}
          contentRender={rndrDgvForm}
          showCloseButton={false}
          resizeEnabled={true}
        />
      </div>
    </React.Fragment>
  )
}

export default ReportTask;