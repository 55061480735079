export const SET_FORM_ID = "SET_FORM_ID";
export const SET_FORM_NAME = "SET_FORM_NAME";
export const SET_FORM_QUESTIONS = "SET_FORM_QUESTIONS";

export const set_form_id = (id: string) => {
    return{
        type: SET_FORM_ID,
        payload: id
    }
}

export const set_form_name = (name: string) => {
    return{
        type: SET_FORM_NAME,
        payload: name
    }
}

export const set_form_questions = (questions: any) => {
    return{
        type: SET_FORM_QUESTIONS,
        payload: questions
    }
}